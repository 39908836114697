import Vue from 'vue'
import VueGtag from "vue-gtag";
// import { createGtm } from "vue-gtm";
import router from "./router";
import App from './App.vue'

// CSS
require('./assets/css/bootstrap.min.css')
require('./assets/css/style.css')
require('dotenv').config()

Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: "G-V5WEXRV65L" }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
