import Vue from 'vue'
import Router from 'vue-router'
import Home from './screens/Home'
import Splash from './screens/mobile/Splash'
import Mobile from './screens/mobile/AcceptTerms'
import Form from './screens/mobile/ForMobile'
import Contract from './components/Contract'


Vue.use(Router)

const router = new Router({
    mode:'history', 
    routes:[
        { path:'/', component: Home, name:'home'},
        { path:'/mobile', component: Mobile, name:'mobile'},
        { path:'/splash', component: Splash, name:'splash'},
        { path:'/form', component: Form, name:'form'},
        { path:'/ncp-contract', component: Contract, name:'contract'},
    ]
})

router.beforeEach((to, from, next) => {
    // if (['mobile','splash','form'].includes(to.name)) {
    //     next('/')
    // }
    next()
})

export default router