<template>
    <div>
        <!-- botão dialog map -->
        <el-button 
            class="brn-locale"
            :loading="loading" 
            type="primary" 
            icon="el-icon-map-location" 
            @click="openMap()" 
            round>
        </el-button>
        
        <!-- <div v-if="address != ''" class="address">
            <el-alert class="col-md-9 col-sm-12 alert-address" :title="address" type="success" show-icon :closable="false" style="border:1px solid #67c23a"></el-alert>
            <el-button class="col-md-2 col-sm-12 mt-2 btn-change-address" type="primary" @click="getCurrentGeolocation(true)" round><strong>Alterar</strong></el-button>
        </div> -->

		<el-dialog title="Escolha a loja onde você deseja receber seu cartão" :fullscreen="true" :visible.sync="dialogMapVisible">
            <el-button class="btn-close-map" type="danger" round @click="closeDialogMap">Fechar Mapa</el-button>
            <div id="map">
                <l-map :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker v-for="(marker, index) in markerLatLng" :key="index" :lat-lng="marker.point">
                        <l-popup>
                            <div id="message" class="mb-3">
                                <strong> PARAÍBA - {{ marker.sg_loja.toUpperCase() }}</strong><br>
                                <strong> {{ marker.no_endereco.toUpperCase() }}</strong><br>
                                <strong> {{ marker.no_bairro.toUpperCase() }}</strong><br>
                                <strong> {{ marker.no_cidade.toUpperCase()+'-'+marker.sg_uf }}</strong><br>
                            </div>
                            <el-button class="center receive-here" type="success" plain round @click="pickStoreFunc(marker)">Receber aqui!</el-button>
                        </l-popup>
                        <l-icon
                            :icon-anchor="staticAnchor"
                            class-name="someExtraClass">
                            <img src="@/assets/images/icon_map.png">
                        </l-icon>
                    </l-marker>
                </l-map>
            </div>
            
        </el-dialog>
    </div>
</template>
<script>
	import Vue from 'vue'
    // import L from 'leaflet';
    import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet';
    import 'leaflet/dist/leaflet.css';
    import swal from 'sweetalert';

    export default {
        name: 'MapStores',
        props:['cidade', 'estado', 'ibge', 'cpf', 'nascimento'],
        components: { LMap, LTileLayer, LMarker, LPopup, LIcon },
        data() {
            return {
                // Map config
                zoom: 12,
                center:[],
                url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
                attribution:'<a href="https://www.armazemparaiba.com.br/" target="_blanc">ARMAZEM PARAIBA</a>',
                markerLatLng: null,
                message: 'no message yet',
                staticAnchor: [16, 37],

                // Data
                dialogMapVisible: false,
                address:'',
                loading:false,
            }
        },
        methods: {
            getCurrentGeolocation(dialog){
                let self = this
                this.loading = true
                this.markerLatLng = null

				Vue.axios({
                    url: `${process.env.VUE_APP_URI_API}propostas/busca-cep?addressdetails=1&city=${this.cidade}&state=${this.estado}&format=json&limit=1`,
                    withCredentials: false,
				})
				.then((response) => {
					if (response.data[0]) {
                        self.center = []
                        self.center.push(response.data[0].lat)
                        self.center.push(response.data[0].lon)
                        this.getStores(response.data[0].lat, response.data[0].lon, self.ibge, dialog)
					}else{
                        self.loading = false
                        swal({
                            title: "Sentimos Muito!!",
                            text: "Infelizmente não atendemos neste CEP.",
                            icon: "info",
                            dangerMode: false,
                        })
                        self.$emit('cleanAdress', true)
					}
				})
				.catch((error)=> {
                    swal({
                        title: "Ops.. Algo de inesperado aconteceu!!",
                        text: "Nossa equipe já está trabalhando para resolver. Tente novamente mais tarde.",
                        icon: "info",
                        dangerMode: false,
                    })
					// eslint-disable-next-line
					console.log(error.response)
				})
            },
            getStores(latitude, longitude, ibge, dialog){
                let self = this
                
				Vue.axios({
					method: "POST",
                    url: `${process.env.VUE_APP_URI_API}geo-lojas/proximos/`,
                    withCredentials: false,
					data:{
						"nr_cpf": self.cpf.replace(/[^\d]+/g,''),
						"dt_nascimento": self.nascimento,
						"latitude": parseFloat(latitude),
						"longitude": parseFloat(longitude),
                        "ibge": parseFloat(ibge)
					}
				})
				.then((response) => {
                    if (response.data.length == 0) {
                        this.$emit('adress', '')
                    }else{
                        this.pickStoreFunc(response.data[0])
                        this.markerLatLng = response.data
                        this.loading = false
                        this.dialogMapVisible = dialog
                        this.$emit('adress', this.markerLatLng)
                    }
                })
                .catch((error) => {
                   console.log(error.response); 
                })
            },
            pickStoreFunc(marker){
                this.$emit('sgLoja', marker.sg_loja),
                this.dialogMapVisible = false
                this.address = `${marker.no_endereco}, ${marker.no_bairro}, ${marker.no_cidade.toUpperCase()}-${marker.sg_uf}`
            },
            openMap(){
                this.dialogMapVisible = true
            },
            closeDialogMap(){
                this.dialogMapVisible = false
            },
        },     
        created() {
            let self = this

            window.onbeforeunload = function(){
                if (self.dialogMapVisible) {
                    return "deseja realmente sair do site?"
                }
            };
        },
    }
</script>
<style>
    #map {
        width: 100%;
        height: 700px;
        margin: 0;
    }
</style>