<template>
    <div  v-loading="loading" id="formProp" class="col-12 mt-3 mb-3">      
          
        <span v-if="this.clientData" class="font-weight-bold text-title text-secondary">Olá {{ this.clientData.no_apelido }}!</span><br/>
        <span class="font-weight-bold text-secondary text-subt text-justify">
            Para continuarmos com a solicitação do seu Cartão Paraíba, precisamos que preencha os dados abaixo.
        </span>

        <el-form class="demo-form-inline mt-4" id="formCad">
            <el-form-item class="font-weight-bold text-secondary" label="Loja de recebimento do cartão?" prop="sg_loja" :error="error.sg_loja">
                <!-- Map -->
                <el-row class="mb-3">      
                    <el-col :span="4" class="btn-locale-resp">
                        <el-button 
                            class="brn-locale"
                            type="primary" 
                            icon="el-icon-map-location" 
                            @click="openMap()"
                            round>
                        </el-button>

                        <el-dialog title="Escolha a loja onde você deseja receber seu cartão" :fullscreen="true" :visible.sync="dialogMapVisible">
                            <el-button class="btn-close-map" type="danger" round @click="closeDialogMap">Fechar Mapa</el-button>
                            <div id="map" style="height:80%">
                                <l-map :zoom="zoom" :center="center">
                                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                    <l-marker v-for="(marker, index) in markerLatLng" :key="index" :lat-lng="marker.point">
                                        <l-popup>
                                            <div id="message" class="mb-3">
                                                <strong> PARAÍBA - {{ marker.sg_loja.toUpperCase() }}</strong><br>
                                                <strong> {{ marker.no_endereco.toUpperCase() }}</strong><br>
                                                <strong> {{ marker.no_bairro.toUpperCase() }}</strong><br>
                                                <strong> {{ marker.no_cidade.toUpperCase()+'-'+marker.sg_uf }}</strong><br>
                                            </div>
                                            <el-button class="center receive-here" type="success" plain round @click="pickStoreFunc(marker)">Receber aqui!</el-button>
                                        </l-popup>
                                        <l-icon
                                            :icon-anchor="staticAnchor"
                                            class-name="someExtraClass">
                                            <img src="@/assets/images/icon_map.png">
                                        </l-icon>
                                    </l-marker>
                                </l-map>
                            </div>
                        </el-dialog>
                    </el-col>

                    <el-col :span="20">
                        <el-select
                            v-if="this.optionsRecebimento.length != 0"
                            style="font-size:small"
                            data-index="2"
                            id="sg_loja" 
                            v-model="sg_loja"
                            clearable 
                            placeholder="Selecione uma opção"
                            :disabled="(optionsRecebimento.length == 1) ? true : false">
                            <el-option
                                v-for="item in optionsRecebimento"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                <el-tooltip 
                                    :content="item.label" 
                                    placement="top">
                                    <span>{{ item.label }}</span> 
                                </el-tooltip>
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>

                <el-alert
                    v-if="this.sg_loja"
                    :title="this.selectedAddress"
                    type="success"
                    :closable="false"
                    show-icon>
                </el-alert>
            </el-form-item>


            <el-form-item class="font-weight-bold text-secondary" label="Escolha o vencimento do seu cartão?" prop="nr_dia_vencto" :error="error.nr_dia_vencto">
                <el-select id="nr_dia_vencto" v-model="nr_dia_vencto" clearable placeholder="Selecione uma opção">
                    <el-option
                        v-for="item in optionsVencimento"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>     
        </el-form>

        <el-button 
            @click="onComplete"
            class="bold w-100 py-3 mt-5" 
            type="success" 
            round >
            Enviar Solicitação
        </el-button>

    </div>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br';
import swal from 'sweetalert';
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet';

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueAxios, axios)
Vue.use(ElementUI, {locale})

// var $ = require('jquery')

export default {
    props:[],
    components: { LMap, LTileLayer, LMarker, LPopup, LIcon },
    data() {
        return {
            loading: false,
            clientData: null,
            optionsRecebimento: [],
            optionsVencimento:[
                { 'value':7, 'label': 'Dia 07' },
                { 'value':14, 'label': 'Dia 14' },
                { 'value':21, 'label': 'Dia 21' },
                { 'value':28, 'label': 'Dia 28' }
            ],
            nr_cpf:'',
            dt_nascimento:'',
            no_apelido:'',
            nr_dia_vencto: null,
            sg_loja:'',

            // Map config
            zoom: 12,
            center:null,
            url:'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
            attribution:'<a href="https://www.armazemparaiba.com.br/" target="_blanc">ARMAZEM PARAIBA</a>',
            markerLatLng: null,
            message: 'no message yet',
            staticAnchor: [16, 37],
            address:'',
            selectedAddress: '',

            dialogMapVisible: false,
            
            error:{},
        }
    },
    created() {
        this.clientData = this.$route.params.data
        if (this.clientData) {
            this.nr_cpf = this.clientData.nr_cpf
            this.dt_nascimento = this.clientData.dt_nascimento
            this.setOptionsAdress(this.clientData.lojas_proximas)
            this.center = this.clientData.point
            this.markerLatLng = this.clientData.lojas_proximas
        }else{
            this.$router.go(-1)
        }
    },
    methods: {
        setOptionsAdress(adress){
            let self = this
            if(adress.length == 0){
                self.cleanAdress();
                swal({
                    title: "Sentimos muito!!",
                    text: "O endereço fornecido está fora da área de nosso atendimento.",
                    icon: "warning",
                    dangerMode: true,
                })
            }
            adress.map(item =>{
                self.optionsRecebimento.push({
                    value: item.sg_loja, 
                    label: `PARAÍBA: ${item.no_endereco}, ${item.no_bairro.trim()}, ${item.no_cidade.toUpperCase().trim()}-${item.sg_uf} (${item.sg_loja})`
                })
            })
        },

        validateForm() {    
            let venc = document.getElementById('formCad').nr_dia_vencto.value
            let loja = document.getElementById('formCad').sg_loja.value
            let data = {}

            if (!venc) {                
                data['nr_dia_vencto'] = 'Este campo deve ser preenchido!'
            }

            if (!loja) {
                data['sg_loja'] = 'Este campo deve ser preenchido!'
            }

            this.error = data            
        },

        pickStoreFunc(marker){
            this.dialogMapVisible = false
            this.sg_loja = marker.sg_loja
            this.address = `${marker.no_endereco}, ${marker.no_bairro}, ${marker.no_cidade.toUpperCase()}-${marker.sg_uf}`
        },

        openMap(){
            this.dialogMapVisible = true
        },

        closeDialogMap(){
            this.dialogMapVisible = false
        },

        onComplete(){
            this.loading = true
            this.validateForm()

            if (Object.keys(this.error).length == 0) {

                var data = new FormData()

                data.append('nr_cpf', this.nr_cpf.replace(/[^\d]+/g,''))
                data.append('dt_nascimento', this.dt_nascimento)
                data.append('sg_loja', this.sg_loja)
                data.append('nr_dia_vencto', this.nr_dia_vencto)

                Vue.axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_URI_API}propostas/cria-mobile/`,
                    withCredentials: false,
                    data: data,
                })
                .then(() => {
                    swal({
                        title: "Parabens!",
                        text: "Sua proposta foi enviada com sucesso. Logo entraremos em contato através do seu e-mail.",
                        icon: "success",
                        dangerMode: true,
                        buttons: false,
                        closeOnClickOutside: false ,
                        closeOnEsc: false,
                    })
                })
                .catch(()=> {

                    swal({
                        title: "Olá, Tudo bem?",
                        text: "Sentimos muito mas não conseguimos solicitar sua proposta. Por favor tente mais tarde ou entre em contato com nossa central do Cartão Paraíba no número 0800-280-1958.",
                        icon: "warning",
                        dangerMode: true,
                        buttons: false,
                        closeOnClickOutside: false ,
                        closeOnEsc: false,
                    })
                    
                    document.getElementById('loader').classList.add("d-none");
                })
                .finally(()=>{
                    this.loading = true
                })
            }
        }
    },
    watch: {
        sg_loja(val){
            let item = this.optionsRecebimento.find(el => el.value == val)
            this.selectedAddress = item.label
        }
    }
}

</script> 
<style scoped>
    .el-select-dropdown__item {
        width: 700px;
    }
    .el-alert--success.is-light {
        border: 1px solid;
    }
    .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
        height: calc( 100% - 5% );
        overflow: hidden;
    }
    .text-title{
        font-size: 1.5em;
    }
    .text-subt{
        font-size: 1.2em;
        text-align: justify!important;
    }
    @media (min-width: 768px){
        #map {
            width: 100%!important;
            height: 40vw!important;
            margin: 0!important;
        }
    }
</style>