<template>
    <div>
        <div v-if="!splash" style="height:100vh">
            <div class="px-3 pt-4 m-0 text-justify" style="height:calc(100% - 15%); overflow:scroll">
                <span class="text-ctt">
                    Declaro estar ciente de minha exclusiva responsabilidade pela veracidade, atualidade e precisão por todas as informações referentes aos dados pessoais fornecidos, estando igualmente de acordo que a proposta seja submetida a prévia análise de crédito, e, caso aprovada, de já aceito o Contrato registrado no livro B-48 sob o nº. 14.179 no Cartório "Nazareno Araújo" - 6° ofício de Notas da Comarca de Teresina-PI, em 31/05/2005.
                    <br><br>
                    Declaro que autorizo e concordo com a realização de consulta ao Sistema de Informações de Crédito (SCR) do Banco Central e que meus dados – e de eventuais dependentes – sejam ali registrados.
                    <br>
                </span>
                <br>
                <span class="font-weight-bold text-ctt m3-5">
                    <!-- <el-checkbox v-model="acceptTerms" class="acceptTerms font-weight-bold">
                    </el-checkbox> -->
                    <span>
                        Declaro que li os 
                        <a id="terms" class="text-primary" @click="viewDocs = true" style="cursor:pointer"> Termos de uso</a>,
                        <a id="contract" class="text-primary" @click="viewDocs = true" style="cursor:pointer">Contrato de emissão e utilização</a> e
                        <a id="pvPolicy" class="text-primary" @click="viewDocs = true" style="cursor:pointer">Política de Privacidade</a>, concordando
                        com a <a id="plCookies" class="text-primary" @click="viewDocs = true" style="cursor:pointer">utilização de cookies</a> pelo site e com as duas declarações acima.
                    </span>

                    <a id="terms" class="text-primary" @click="viewDocs = true" style="cursor:pointer"> Termos de Uso </a> e 
                    <a id="contract" class="text-primary" @click="viewDocs = true" style="cursor:pointer">Contrato de emissão e utilização</a>
                </span>
            </div>

            <el-footer class="py-3 m-0 text-center" style="height:10vh;">
                <el-button class="font-weight-bold" type="success" round @click="acceptTerms">Aceitar e Continuar</el-button>
            </el-footer>

            <el-drawer
                title=""
                class="btn-close"
                :visible.sync="viewDocs"
                size="100%"
                direction="ttb"
                >
                    <template v-if="drawerContract">
                        <Contract/>
                    </template>
                    <template v-if="drawerTerms">
                        <Terms/>
                    </template>
                    <template v-if="drawerPrivate">
                        <PrivatePolicy/>
                    </template>
                    <template v-if="drawerCookies">
                        <Cookies/>
                    </template>
            </el-drawer>
        </div>
        <Splash v-if="splash"/> 
    </div>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Cookies from '@/components/Cookies_policy'
import PrivatePolicy from '@/components/Private_policy'
import Contract from '@/components/Contract'
import Terms from '@/components/Terms'
import Splash from '@/screens/mobile/Splash'
import swal from 'sweetalert'

Vue.use(VueAxios, axios)

export default {
    components:{ Contract, Terms, PrivatePolicy, Cookies, Splash },
    data() {
        return {
            splash: false,
            clientData: 'null',
            
            //Contract and Usage Terms
            viewDocs: false,
            drawerContract: false,
            drawerTerms: false,
            drawerPrivate: false,
            drawerCookies: false,
        }
    },
    methods:{
        acceptTerms(){
            this.splash = true
            router.push({
                name: 'form',
                params: {
                    data: this.clientData
                }
            });
        }
    },
    watch: {
        viewDocs(){
            let self =this
            let type = event.currentTarget.id

            self.drawerContract = false
            self.drawerTerms = false
            self.drawerPrivate = false
            self.drawerCookies = false

            if (type == 'terms') {
                self.drawerTerms = true
            }
            if (type == 'contract') {
                self.drawerContract = true
            }
            if (type == 'pvPolicy') {
                self.drawerPrivate = true
            }
            if (type == 'plCookies') {
                self.drawerCookies = true
            }
        }
    },
    created() {
        this.splash = true

        var data = new FormData()
        data.append('nr_cpf', this.$route.query.cpf.replace(/[^\d]+/g,''))
        data.append('dt_nascimento', this.$route.query.nascimento)
        
        Vue.axios({
            method: 'post',
            url: `${process.env.VUE_APP_URI_API}propostas/inicializa-mobile/`,
            withCredentials: false,
            data: data,
        })
        .then((response) => {
            let self = this
            this.clientData = response.data            
            this.clientData['nr_cpf'] = this.$route.query.cpf            
            this.clientData['dt_nascimento'] = this.$route.query.nascimento   

            if (response.data.status) {
                switch (response.data.status) {
                    case 'PRÉ-APROVADA':
                        swal({
                            title: `Olá, ${response.data.no_apelido}!`,
                            text: "Sua proposta ja está PRE APROVADA, compareca a loja de escolha para receber seu cartão.",
                            icon: "info",
                            buttons: false,
                            closeOnClickOutside: false ,
                            closeOnEsc: false,
                        })
                        break
                    case 'EM ANÁLISE':
                        swal({
                            title: `Olá, ${response.data.no_apelido}!`,
                            text: "Obigado por escolher o Cartão Paraíba!! Sua proposta ja está EM ANÁLISE, em até 3 dias úteis você receberá uma resposta no email cadastrado.",
                            icon: "info",
                            buttons: false,
                            closeOnClickOutside: false ,
                            closeOnEsc: false,
                        })
                        break;
                    case 'REENVIO':
                        swal({
                            title: `Olá, ${response.data.no_apelido}!`,
                            text: "Sua proposta está pendente por incompatibilidade ou má qualidade nas imagens que foram enviadas. Precisamos que reenvie novas imagens para darmos continuidade a sua proposta.",
                            icon: "info",
                            buttons: {
                                text: "Reenviar Imagens",
                            },
                        })
                        .then(() => {
                            self.$emit('action', response.data)
                        })
                        break;
                    case 'REPROVADA':
                        swal({
                            title: `Olá, seu Cartão Paraíba não foi aprovado no momento`,
                            text: "Tente novamente após 90 dias pelo site ou procure uma das lojas do Armazém Paraíba.",
                            icon: "info",
                            buttons: false,
                            closeOnClickOutside: false ,
                            closeOnEsc: false,
                        })
                        break;
                }
            }else{
                self.splash = false
            }
        })
        .catch((error)=> {
            let data = error.response.data

            switch (error.response.status) {
                case 400:
                    swal({
                        title: "Atenção!",
                        text: (data.nr_cpf) ? data.nr_cpf[0] : data.dt_nascimento[0],
                        icon: "warning",
                        buttons: false,
                        closeOnClickOutside: false , 
                        closeOnEsc: false ,
                    })
                    break;
                case 500:
                    swal({
                        title: "Olá, Tudo bem?",
                        text: "Sentimos muito mas não conseguimos solicitar sua proposta. Por favor tente mais tarde ou entre em contato com nossa central do Cartão Paraíba no número 0800-280-1958.",
                        icon: "warning",
                        dangerMode: true,
                        buttons: false,
                        closeOnClickOutside: false , 
                        closeOnEsc: false ,
                    })
                    break;
            }

            document.getElementById('loader').classList.add("d-none");
        })
    },
}
</script>

<style scope>
    .btn-close [class*=" el-icon-"]{
        background: #ff000094;
        border-radius: 50%;
        padding: 5px;
        color: white;
    }

    .text-ctt{
        font-size: 1.2em;
        color: black;
    }
</style>