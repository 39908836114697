<template>
    <div>
        <a v-if="mobile" class="btn btn-outline-primary btn-ptint-contract" type="primary" href="docs/contract_ncp.pdf" download>
            <i class="el-icon-download"></i>
            Baixar
        </a>

        <el-button v-else class="btn-ptint-contract" type="primary" round @click="printContract()">
            <i class="el-icon-printer"></i>
            Imprimir
        </el-button>

        <div class="font-contract contract-layout">
            <div v-html="currentDoc"></div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
    props:['tipo'],
    data() {
        return {
            mobile: this.isMobile(),
            currentDoc: ""
        }
    },
    methods: {
        printContract(){
            window.print()
        },
        //is mobile?
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
    },
    mounted() {
        Vue.axios(`${process.env.VUE_APP_URI_API}propostas/busca-termos?tipo=${this.tipo}`)
        .then((res) => {
            this.currentDoc = res.data.texto
        })
        .catch((error)=>{
            // eslint-disable-next-line no-console
            console.log(error)
        })
    },
}
</script>
<style>
    .contract-layout{
        margin-left: 20%!important;
        margin-right: 20%!important;
        color: black;
    }
    .btn-ptint-contract{
        width: auto!important;
        margin: 20px!important;
        font-weight: bold!important;
        position: absolute!important;
        top: 3px!important;
        text-decoration: none;
        border-radius: 30px!important;
    }
    .font-contract{
        height: 80vh;
        overflow: auto;
    }

    @media print{
        button{
            display: none!important;
        }
        .contract-layout {
            margin:1px!important;
            color: black;
        }
        .font-contract{
            height: 100%!important;
            overflow: hidden!important;
        }
    }

    @media only screen and (max-width: 800px) {
        .font-contract.contract-resp{
            margin-top: 20px;
        }
        .font-contract .title-contract{
            font-size: 1em!important;
            margin-bottom: 1rem!important;
        }
    }
</style>