<template>
    <div v-loading="loading">
        <template v-if="formResend.no_apelido">
            <h4 class="font-weight-bold">{{ formResend.no_apelido }},</h4>
            <h6 class="font-weight-bold text-secondary mt-3 mb-5">
                Envie os documento abaixo solicitados para continuarmos com sua proposta.
            </h6>
        </template>

        <el-form 
            error-color="#dc3545"
            :model="formResend" 
            class="demo-form-inline"
        >
            <div v-if="formResend.img_frente_doc">
                <!-- webcam -->
                <el-form-item class="font-weight-bold text-secondary text-center " label="MANDE UMA FOTO SUA" prop="img_foto" :error="error.img_foto">
                    <el-row class="text-left">       
                        <template v-if="hasWebCam && formResend.img_foto.length == 0">
                            <el-button 
                                style="font-size: 1.3em" 
                                icon="el-icon-camera-solid" 
                                @click="beforeSendPhoto('cam')"
                                round
                            >Tirar Foto</el-button>                 
                            <el-button 
                                style="font-size: 1.2em" 
                                icon="el-icon-upload" 
                                @click="beforeSendPhoto('up')"
                                round
                            >Enviar Arquivo</el-button>
                        </template>                 
                    </el-row>
                    
                    <template class="mt-3" v-if="enableCam && accessWebcam && !enableUpFhoto && !img">
                        <WebCam 
                            class="webcam-view mt-3"
                            ref="webcam"
                            :deviceId="deviceId"
                            width="auto"
                            height="100%"
                            @cameras="onCameras"
                            :isFrontCam="frontCam"
                        ></WebCam>
                        <el-button @click="onCapture" type="danger" icon="el-icon-camera" round> Capturar Imagem</el-button>
                    </template>  
                    
                    <template v-if="img" class="mt-3">
                        <el-image 
                            class="img-cam"
                            :src="img" 
                            :preview-src-list="srcList">
                        </el-image>
                    </template>

                    <div v-if="img" class="mt-3">
                        <el-button @click="previewCam" type="info" icon="el-icon-search" round plain>Visualizar</el-button>
                        <el-button @click="chageImgCam" type="danger" icon="el-icon-delete" round plain>Exluir</el-button>
                    </div>

                    <div v-if="enableCam && !accessWebcam && !img" class="alert alert-warning mt-3" role="alert" style="line-height: initial">
                        <h4 class="alert-heading font-weight-bold">Importante!</h4>
                        <p>
                            Para enviar uma foto sua, é necessário que você permita o acesso a webcam. 
                            <br> Após liberação clique no botão abaixo.
                        </p>
                        <hr>
                        <el-button @click="beforeSendPhoto('cam')" type="warning" round plain>Já liberei o acesso!!</el-button>
                    </div>
                </el-form-item>

                <!-- upload photo -->
                <el-form-item v-if="(enableUpFhoto && !enableCam) || !hasWebCam" class="font-weight-bold text-secondary" prop="img_foto" :error="error.img_foto">
                    <el-upload
                        data-index="5"
                        id="img_foto"
                        class="uploadInput"
                        action=""
                        list-type="picture-card"
                        accept="image/jpg, image/jpeg, image/png, image/bmp"
                        :limit="1"
                        :auto-upload="false"
                        :before-upload="onBeforeUpload"
                        :on-change="toggleUpload"
                        :on-preview="handlePreview"
                        :on-remove="toggleUpload"
                        :before-remove="beforeRemove"
                        :file-list="formResend.img_foto"
                        :http-request="handleFile"
                    >
                        <small class="font-weight-bold text-secondary"><i class="el-icon-upload"></i> Clique aqui ou arraste e solte para enviar!</small>
                    </el-upload>
                </el-form-item>

                <el-form-item class="font-weight-bold text-secondary" label="DOCUMENTO DE IDENTIFICAÇÃO" prop="tp_documento" :error="error.tp_documento">
                    <el-radio-group id="tp_documento" data-index="5" v-model="formResend.tp_documento" @change="tipeDoc">
                        <el-radio label="C">CNH</el-radio>
                        <el-radio label="R">RG</el-radio>
                    </el-radio-group>
                </el-form-item>

                <div class="row" v-if="formResend.tp_documento != []">
                    <el-form-item class="font-weight-bold text-secondary col-md col-sm-12" 
                        :label="(this.formResend.tp_documento == 'R') ? 'FOTO DA FRENTE DO RG' : 'FOTO DA CNH'" 
                        prop="img_frente_doc" 
                        :error="error.img_frente_doc"
                    >
                        <el-upload
                            data-index="5"
                            id="img_frente_doc"
                            class="uploadInput"
                            action=""
                            list-type="picture-card"
                            accept=" image/jpg, image/jpeg, image/png, image/bmp"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="toggleUpload"
                            :on-preview="handlePreview"
                            :on-remove="toggleUpload"
                            :before-remove="beforeRemove"
                            :file-list="formResend.img_frente_doc"
                            :http-request="handleFile"
                        >
                            <small class="font-weight-bold text-secondary">
                                <img v-if="this.formResend.tp_documento == 'C'" width="60" src="@/assets/images/CPF.svg" alt="">
                                <img v-if="this.formResend.tp_documento == 'R'" width="60" src="@/assets/images/RG_frente.svg" alt="">
                            </small>
                        </el-upload>
                    </el-form-item>         

                    <el-form-item  v-if="formResend.tp_documento === 'R'" class="font-weight-bold text-secondary col-md col-sm-12" :label="'FOTO DO VERSO DO RG'" prop="img_verso_doc" :error="error.img_verso_doc">
                        <el-upload
                            data-index="5"
                            id="img_verso_doc"
                            class="uploadInput"
                            action=""
                            list-type="picture-card"
                            accept=" image/jpg, image/jpeg, image/png, image/bmp"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="toggleUpload"
                            :on-preview="handlePreview"
                            :on-remove="toggleUpload"
                            :before-remove="beforeRemove"
                            :file-list="formResend.img_verso_doc"
                            :http-request="handleFile"
                        >
                            <small class="font-weight-bold text-secondary">
                                <img v-if="this.formResend.tp_documento == 'R'" width="80" src="@/assets/images/RG_verso.svg" alt="">
                            </small>
                        </el-upload>
                    </el-form-item>            
                </div>
            </div>

            <div v-if="formResend.img_c_residencia" class="row">
                <el-form-item class="font-weight-bold text-secondary col-md col-sm-12"  label="COMPROVANTE DE RESIDÊNCIA" prop="img_c_residencia" :error="error.img_c_residencia">
                    <el-upload
                        data-index="5"
                        id="img_c_residencia"
                        class="uploadInput"
                        action=""
                        list-type="picture-card"
                        accept=" image/jpg, image/jpeg, image/png, image/bmp"
                        :limit="1"
                        :auto-upload="false"
                        :on-change="toggleUpload"
                        :on-preview="handlePreview"
                        :on-remove="toggleUpload"
                        :before-remove="beforeRemove"
                        :file-list="formResend.img_c_residencia"
                        :http-request="handleFile"
                    >
                        <small class="font-weight-bold text-secondary">
                            <img width="80" src="@/assets/images/Invoice.svg" alt="">
                        </small>
                    </el-upload>
                </el-form-item>            
            </div>
        </el-form>

        <el-row class="py-3 text-center">
            <el-button type="success" @click="sendDocs" round>Enviar Documentos</el-button>
        </el-row>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br';
import { WebCam } from 'vue-cam-vision'
import swal from 'sweetalert';

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueAxios, axios)
Vue.use(ElementUI, {locale})

var $ = require('jquery')

export default {
    props:['data'],
    components:{ WebCam },  
    data() {
        return {
            formResend:{},
            
            // Params upload
            enableUpFhoto: false,
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            showUpload: true,
            visitedDocs: false,

            // WebCam
            hasWebCam: navigator.mediaDevices,
            accessWebcam: null,
            enableCam: false,
            img: null,
            srcList: [],
            camera: null,
            deviceId: null,
            devices: [],
            frontCam: true,
            report: null,
            visitedCam: false,
            visitedUphoto: false,

            //Validate
            error:{},

            //Loadin
            loading: false,
        }
    },
    methods: {
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        beforeRemove() {
            let element = this.findParentClass(event.currentTarget, 'uploadInput')

            this.img = null
            this.formResend.img_foto = []
            return $(`${element.id}`).removeClass('hideUpload')
        },
        findParentClass(el, className) {
            while (el.parentNode) {
                el = el.parentNode;
                if (el.className.includes(className))
                return el;
            }
            return null;
        },
        toggleUpload(file, fileList) {
            let element = this.findParentClass(event.currentTarget, 'uploadInput')
            
            this.formResend[element.id] = fileList
            $(`#${element.id}`).toggleClass("hideUpload")
            this.showUpload = !this.showUpload
        },
        tipeDoc(){
            if(this.formResend.tp_documento === "C"){
                this.formResend.img_verso_doc = []
            }
        },
        handleFile(){},
        cleanFormResend(){
            this.formResend = {}
        },
        sendDocs(){
            this.loading = true

            let self = this
            let data = new FormData();

            Object.keys(self.formResend).map((key)=> {
                let value = self.formResend[key]
                if(typeof(value) == 'object'){
                    if (value.length > 0) {
                        data.append(key, value[0].raw)
                    }
                }else{
                    if (['nr_cpf'].includes(key)) {
                        value = value.replace(/[^\d]+/g,'')
                    }
                    if (value != null) {
                        data.append(key, value)
                    }   
                }
            })

            Vue.axios({
                method: 'patch',
                url: `${process.env.VUE_APP_URI_API}propostas/${self.formResend.id}/`,
                withCredentials: false,
                data: data,
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(() => {
                this.loading = false
                swal({
                    title: "Proposta reenviada!",
                    text: "Reenviamos seus documentos para análise, assim que terminarmos você receberá um e-mail.",
                    icon: "success",
                    dangerMode: false,
                })
                .then(() => {
                    this.cleanFormResend()
                    location.reload(true)
                })
            })
            .catch((error)=>{
                let self = this

                if (error.response.status == 400) {
                    let el = error.response.data
                    self.error = {}
                    
                    Object.keys(el).forEach((key) => self.error[key] = el[key].join('<br>'))
                    self.loading = false
                }else{
                    swal({
                        title: "Opss! Algo de inesperado aconteceu.",
                        text: "Nossa equipe já está trabalhando para resolver, tente novamente mais tarde.",
                        icon: "warning",
                        dangerMode: true,
                    })
                    self.loading = false
                }

                if (error.response.status == 500) {
                    swal({
                        title: "Opss! Algo de inesperado aconteceu.",
                        text: "Nossa equipe já está trabalhando para resolver, tente novamente mais tarde.",
                        icon: "warning",
                        dangerMode: true,
                    })
                    self.loading = false
                }
                console.log(error.response);
            })
            .finally(()=>{
                this.loading = false
            })
        },
        beforeSendPhoto(type){
            let self = this
            
            //message if webcam
            if (!this.visitedCam) {           
                if (type == 'cam') {
                    self.visitedCam = true
                    self.$alert(
                        `<el-row>
                            <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
                                <el-card :body-style="{ padding: '0px' }">
                                <img src="${require('@/assets/images/selfie.png')}" class="image">
                                <div style="padding: 14px;">
                                    <span class="mb-3 font-weight-bold" style="font-weight: bold;font-size: 1.5em;">
                                        Para obter uma foto de boa qualidade:
                                    </span>
                                    <br>
                                    <span style="font-size: 1.2em;">
                                        Tire óculos, bonés ou a acessórios. Procure um local claro e posicione seu rosto no centro da câmera.
                                    </span>
                                    </div>
                                </div>
                                </el-card>
                            </el-col>
                        </el-row>`, 
                        {
                            confirmButtonText: 'OK, Entendi',
                            center: true,
                            dangerouslyUseHTMLString: true
                        }
                    ).then(() => {
                        self.pickTypeUpPhoto(type);
                    })
                }
            }else{
                self.pickTypeUpPhoto(type);
            }

            //message if upload
            if (!this.visitedCam) {           
                if (type == 'up') {
                    self.visitedUphoto = true
                    self.$alert(
                        `<el-row>
                            <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
                                <el-card :body-style="{ padding: '0px' }">
                                <img src="${require('@/assets/images/send_photo.png')}" class="image">
                                <div style="padding: 14px;">
                                    <span class="font-weight-bold" style="font-weight: bold;font-size: 1.5em;">
                                        Anexe uma foto de boa qualidade:
                                    </span>
                                    <br>
                                    <span class="mt-3" style="font-size: 1.2em;">
                                        Sua foto não deve ter acessórios como óculos, bonés e etc.
                                    </span>
                                    </div>
                                </div>
                                </el-card>
                            </el-col>
                        </el-row>`, 
                        {
                            confirmButtonText: 'OK, Entendi',
                            center: true,
                            dangerouslyUseHTMLString: true
                        }
                    ).then(() => {
                        self.pickTypeUpPhoto(type);
                    })
                }
            }else{
                self.pickTypeUpPhoto(type);
            }
        },
        onBeforeUpload(file){
            let self = this
            const isIMAGE = file.type === 'image/jpg' || 'image/jpeg' || 'image/png' || 'image/bmp';
            const isLt1M = file.size / 1024 / 1024 < 1;

            if (!isIMAGE) {
                self.error('Este formato de arquivo não é aceitável. Tente "jpg", "jpeg", "png" ou "bmp"');
            }
            if (!isLt1M) {
                self.error('O tamanho do arquivo de upload não pode exceder 1MB!');
            }
            return isIMAGE && isLt1M;
        },

        // WebCam functions
        onCapture () {
            let self = this
            this.$refs.webcam.capture()
            .then(blob => {
                let typeImg = blob.split(';')[0].split(':')[1]
                let extension = typeImg.split('/')[1]
                let blobImage = self.toBlob(blob)
                let imgCam = { raw: new File([blobImage], `webCamImage.${extension}`, { type:typeImg })}

                self.img = blob
                self.srcList.push(self.img)
                self.formResend.img_foto.push(imgCam)
            })
        },

        //convert image base64 to blob
        toBlob(blob){
            var mime = blob.split(';base64,')[0].split(':')[1]
            var base64 = blob.split(';base64,')[1]
            var sliceSize = 1024;
            var byteChars = window.atob(base64);
            var byteArrays = [];

            for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
                var slice = byteChars.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, {type: mime});
        },
        onCameras (cameras) {
            this.devices = cameras
        },
        chageImgCam(){
            this.img = null
            this.formResend.img_foto = []
        },
        previewCam(){
            $('.img-cam img').click()
        },
        pickTypeUpPhoto(type){
            if(type == 'cam'){
                if (navigator.mediaDevices.getUserMedia) {
                    let self = this

                    navigator.mediaDevices.getUserMedia({ video: true })
                    .then(function () {
                        self.enableCam = true
                        self.enableUpFhoto = false
                        self.accessWebcam = true
                    })
                    .catch(function () { 
                        self.enableCam = true
                        self.enableUpFhoto = false
                        self.accessWebcam = false
                        self.$notify({
                            title: 'Atenção!!',
                            message: 'O acesso a webcam não está liberado!',
                            type: 'warning'
                        });
                        // self.$message.error('Oops, O acesso a webcam não está liberado!');         
                    })
                }
            }
            if (type == 'up') {
                this.enableCam = false
                this.enableUpFhoto = true
            }
        },

    },
    created() {
        let self = this
        
        this.formResend.id = this.data.id
        this.formResend.no_apelido = this.data.no_apelido
        this.formResend.nr_cpf = this.data.nr_cpf
        this.formResend.dt_nascimento = this.data.dt_nascimento
        this.data.campos.map(element =>{
            self.formResend[element] = []
        })
    },
}
</script>