<template>
    <div>
        <a v-if="mobile && routeName != 'contract'" class="btn btn-outline-primary btn-ptint-contract" type="primary" href="docs/contract_ncp.pdf" download>
            <i class="el-icon-download"></i>
            Baixar
        </a>

        <el-button v-if="routeName != 'contract'" class="btn-ptint-contract" type="primary" round @click="printContract()">
            <i class="el-icon-printer"></i>
            Imprimir
        </el-button>

        <div :class="(routeName != 'contract') ? 'font-contract contract-resp' : ''">
            <h3 class="col-md-11 mb-5 text-center font-weight-bold title-contract">Contrato de emissão e utilização do Cartão Paraíba</h3>
            <div :class="(routeName != 'contract') ? 'content-cont' : ''">
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">IMPORTANTE:</span><span lang="pt-BR">&nbsp;Uma c&oacute;pia deste contrato &eacute; entregue ao associado TITULAR, juntamente com o CART&Atilde;O.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><u>Leia-o com bastante aten&ccedil;&atilde;o</u></span><span lang="pt-BR">.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;">Ao aceitar, assinar e usar o CART&Atilde;O DE COMPRAS PARA&Iacute;BA, o associado estar&aacute; concordando e aderindo, integralmente, aos termos e condi&ccedil;&otilde;es relativos ao seu uso.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: left;"><strong><span lang="pt-BR">TEOR DO CONTRATO COM O ASSOCIADO DO CART&Atilde;O PARA&Iacute;BA</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;">(Registrado no Livro B-48 sob o n&ordm;. 14.179 no Cart&oacute;rio &ldquo;Nazareno Ara&uacute;jo&rdquo; &ndash; 6&ordm; of&iacute;cio de Notas da Comarca de Teresina-PI, em 31/05/2005)&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">(Aditivo no. 01 registro micro filmado sob o n&ordm;</span><span lang="pt-BR">. 18.892 no Cart&oacute;rio &ldquo;Nazareno Ara&uacute;jo&rdquo; &ndash;&nbsp;</span><span lang="pt-BR"><strong>6&ordm; of&iacute;cio de Notas</strong></span><span lang="pt-BR">&nbsp;da Comarca de Teresina-PI, em 20/10/2008)</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">OBJETO</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">Pelo presente&nbsp;</span><span lang="pt-BR"><u>INSTRUMENTO PARTICULAR DE CONTRATO DE EMISS&Atilde;O E UTILIZA&Ccedil;&Atilde;O</u></span><span lang="pt-BR">&nbsp;do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, de um lado, a firma&nbsp;</span><span lang="pt-BR"><strong>CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS</strong></span><span lang="pt-BR">, com sede em Teresina (PI), &agrave; rua Jo&atilde;o Cabral, 607,&nbsp;</span><span lang="pt-BR">centro</span><span lang="pt-BR">&nbsp;&ndash;&nbsp;</span><span lang="pt-BR">n</span><span lang="pt-BR">orte, inscrita no Minist&eacute;rio da Fazenda sob o n&ordm; 06.862.627/0001-38, como&nbsp;</span><span lang="pt-BR"><strong>EMITENTE</strong></span><span lang="pt-BR">; e, de outro lado, o&nbsp;</span><span lang="pt-BR"><strong>TITULAR</strong></span><span lang="pt-BR">&nbsp;&ndash; Cliente Titular do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, pessoa f&iacute;sica que aderiu ao&nbsp;</span><span lang="pt-BR"><strong>SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA</strong></span><span lang="pt-BR">, na forma da cl&aacute;usula 01, estabelecem as seguintes cl&aacute;usulas e condi&ccedil;&otilde;es para a utiliza&ccedil;&atilde;o do CART&Atilde;O DE COMPRAS PARA&Iacute;BA:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">DEFINI&Ccedil;&Otilde;ES</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>a) CART&Atilde;O ADICIONAL</strong>: Cart&atilde;o cuja emiss&atilde;o foi solicitada pelo TITULAR, para ser utilizado por pessoa f&iacute;sica, parente sua ou n&atilde;o, sob sua exclusiva e inteira responsabilidade, dentro dos par&acirc;metros aqui estabelecidos. Esta op&ccedil;&atilde;o s&oacute; estar&aacute; dispon&iacute;vel para os CART&Otilde;ES PARA&Iacute;BA, cujo TITULAR optar, na proposta de ingresso ao SISTEMA DE CART&Atilde;O COMPRAS PARA&Iacute;BA, pela modalidade de pagamento tipo EXTRATO.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>b) LIMITE DE COMPRAS</strong>: &Eacute; o valor m&aacute;ximo da obriga&ccedil;&atilde;o mensal, disponibilizado pela EMITENTE e consentido pelo TITULAR, para compras &agrave; vista,&nbsp;cujo limite de cr&eacute;dito &eacute; de exclusiva fixa&ccedil;&atilde;o pela EMITENTE segundo seus crit&eacute;rios de an&aacute;lise.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>c) LIMITE ADICIONAL</strong>: &Eacute; um valor que a EMITENTE p&otilde;e &agrave; disposi&ccedil;&atilde;o do TITULAR, para compras parceladas, na medida que o LIMITE DE COMPRAS (obriga&ccedil;&atilde;o mensal) possa suportar no m&ecirc;s. Os valores parcelados a cargo da EMITENTE, no ato aceitos pelo TITULAR, circunstancialmente, dado &agrave; sua natureza adicional, poder&atilde;o ser acrescidos de taxas de financiamentos praticadas pelo mercado financeiro no respectivo per&iacute;odo financiado, prefixadas e informadas pelo estabelecimento no momento da compra, com a pronta aceita&ccedil;&atilde;o do TITULAR. Assim com o limite de compras,&nbsp;o limite adicional &eacute; de exclusiva fixa&ccedil;&atilde;o pela EMITENTE segundo seus crit&eacute;rios de an&aacute;lise.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>d) CENTRAL DE ATENDIMENTO:</strong></span><span lang="pt-BR">&nbsp;Servi&ccedil;os telef&ocirc;nicos colocados &agrave; disposi&ccedil;&atilde;o dos portadores do CART&Atilde;O DE COMPRAS PARA&Iacute;BA,&nbsp;</span><span lang="pt-BR">exclusivamente</span><span lang="pt-BR">&nbsp;em dias e hor&aacute;rios comerciais. Consideram-se dias e hor&aacute;rios comerciais, para os efeitos deste instrumento, aqueles em que qualquer dos estabelecimentos credenciados pela EMITENTE se encontre funcionando com atendimento aberto ao p&uacute;blico.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>e) COMPROVANTE DE VENDA</strong></span><span lang="pt-BR">: Documento comprobat&oacute;rio das transa&ccedil;&otilde;es realizadas com o CART&Atilde;O DE COMPRAS PARA&Iacute;BA, podendo ser emitido tanto no processo manual quanto no processo autom&aacute;tico e assinado pelo TITULAR ou ADICIONAL.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>f) BOLETIM DE PROTE&Ccedil;&Atilde;O</strong>: Rela&ccedil;&atilde;o, sob sigilo e reservada, &agrave; disposi&ccedil;&atilde;o do TITULAR, se lhe disser respeito, dos CART&Otilde;ES impedidos de uso, cancelado(s) por extravio, furto, roubo, fraude, falsifica&ccedil;&atilde;o ou inadimplemento contratual.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>g) ENCARGOS FINANCEIROS</strong></span><span lang="pt-BR">: S&atilde;o aqueles decorrentes do financiamento do saldo remanescente na conta corrente do CART&Atilde;O do TITULAR, e, pelos custos do financiamento sobre o prazo, nos casos de compras parceladas.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>h) ESTABELECIMENTOS CREDENCIADOS</strong></span><span lang="pt-BR">: Lojas do&nbsp;</span><span lang="pt-BR">ARMAZ&Eacute;M</span><span lang="pt-BR">&nbsp;PARA&Iacute;BA pertencentes &agrave; empresa CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS,&nbsp;</span><span lang="pt-BR">CNPJ n.&ordm; 06.862.627/0001-38 (matriz) e suas filiais.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>i) FATURA</strong></span><span lang="pt-BR">: Composi&ccedil;&atilde;o dos lan&ccedil;amentos levados a d&eacute;bito da CONTA CART&Atilde;O do TITULAR no per&iacute;odo correspondente ao fato gerador da obriga&ccedil;&atilde;o contra&iacute;da.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>j) EXTRATO</strong></span><span lang="pt-BR">: Demonstrativo da FATURA, emitido e enviado mensalmente ao TITULAR do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, onde s&atilde;o apresentados os d&eacute;bitos e cr&eacute;ditos do per&iacute;odo correspondente. &Eacute; utilizado para pagamento de saldos remanescentes na conta corrente do TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>k) CARN&Ecirc;</strong>: Demonstrativo da Fatura contendo os dados da compra efetuada pelo TITULAR atrav&eacute;s do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, al&eacute;m do nome do comprador, seu endere&ccedil;o e as parcelas com seus respectivos vencimentos, emitido e entregue ao cliente no momento da venda, para pagamento das presta&ccedil;&otilde;es prefixadas nele indicado. Isto quando o TITULAR, e somente este, no momento da ades&atilde;o ao SISTEMA DE CART&Atilde;O PARA&Iacute;BA, optar pela forma de pagamento &ldquo;CARN&Ecirc;&rdquo;.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><strong>l) PAGAMENTO AVULSO</strong>: Procedimento mantido &agrave; disposi&ccedil;&atilde;o exclusiva do TITULAR de um CART&Atilde;O DE COMPRAS PARA&Iacute;BA, &agrave; sua escolha, nas lojas do&nbsp;ARMAZ&Eacute;M&nbsp;PARA&Iacute;BA, destinado ao pagamento total ou parcial do EXTRATO da FATURA mensal, quando este, involuntariamente, n&atilde;o chegar at&eacute; as m&atilde;os do TITULAR at&eacute; a data do vencimento da mesma, ou, ainda, de pagamentos de amortiza&ccedil;&atilde;o, total ou parcial, de presta&ccedil;&atilde;o prefixada em CARN&Ecirc; de pagamento.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR"><strong>m) PROPOSTA PARA EMISS&Atilde;O DO CART&Atilde;O PARA&Iacute;BA</strong></span><span lang="pt-BR">: Documento de solicita&ccedil;&atilde;o de ingresso no SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA, no qual constar&aacute; a autoriza&ccedil;&atilde;o da emiss&atilde;o de cart&atilde;o TITULAR e/ou ADICIONAL, caso aprovado o ingresso pretendido pelo TITULAR, em seu proveito ou de outrem sob sua exclusiva responsabilidade (ADICIONAL), e, ainda, dentre outras informa&ccedil;&otilde;es, os dados cadastrais e assinatura do TITULAR, data do vencimento da FATURA mensal, forma de pagamento (CARN&Ecirc; ou EXTRATO) e as principais cl&aacute;usulas deste instrumento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">ADES&Atilde;O AO SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1 A ades&atilde;o ao SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA ser&aacute; efetivada exclusivamente pelo TITULAR, por meio de qualquer um dos atos a seguir:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) Assinatura de pr&oacute;prio punho da Proposta de Ades&atilde;o ou do recibo de entrega do Cart&atilde;o, ou</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) Solicita&ccedil;&atilde;o de libera&ccedil;&atilde;o do cart&atilde;o, atrav&eacute;s da Central de Atendimento CART&Atilde;O DE COMPRAS PARA&Iacute;BA (telefones indicados nas faturas), ou</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) Utiliza&ccedil;&atilde;o do Cart&atilde;o em qualquer estabelecimento credenciado pela EMITENTE, ou</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">d) Pagamento de qualquer natureza de extrato ou carn&ecirc; da conta cart&atilde;o, conforme tenha sido a op&ccedil;&atilde;o do cliente no ingresso ao SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1.1</span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR"> No caso de assinatura de TERCEIROS no recibo de entrega do cart&atilde;o, a ades&atilde;o ser&aacute; efetivada a partir da solicita&ccedil;&atilde;o de libera&ccedil;&atilde;o pelo TITULAR do cart&atilde;o ou de sua utiliza&ccedil;&atilde;o pelo mesmo em qualquer estabelecimento credenciado pela EMITENTE.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1.2</span><span lang="pt-BR">&nbsp;Integra o presente contrato:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) As cl&aacute;usulas gerais constantes deste instrumento e suas altera&ccedil;&otilde;es.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) O CART&Atilde;O DE COMPRAS PARA&Iacute;BA, o Boletim de Prote&ccedil;&atilde;o e a Proposta de ingresso.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) Os Comprovantes de Vendas de utiliza&ccedil;&atilde;o do CART&Atilde;O.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">d) O extrato, o carn&ecirc;, o formul&aacute;rio de pagamento avulso e demais formul&aacute;rios pr&oacute;prios do SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">e) As comunica&ccedil;&otilde;es das altera&ccedil;&otilde;es relativas a este instrumento, referente aos produtos e servi&ccedil;os do SISTEMA CART&Atilde;O DE COMPRAS PARA&Iacute;BA.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">f) Os documentos e/ou grava&ccedil;&otilde;es que comprovem, na forma da cl&aacute;usula primeira deste instrumento, a ades&atilde;o ao SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1.3 </span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR">O ARMAZ&Eacute;M PARA&Iacute;BA (CLAUDINO S/A - LOJAS DE DEPARTAMENTOS) zela pela privacidade dos dados de seus usu&aacute;rios, nos termos da Pol&iacute;tica de Privacidade e Seguran&ccedil;a dispon&iacute;vel em www.armazemparaiba.com.br e veda a utiliza&ccedil;&atilde;o dos seus sites, plataformas e aplica&ccedil;&otilde;es de Internet para o envio de mensagens n&atilde;o solicitadas de qualquer natureza via correio eletr&ocirc;nico. Contudo, o TITULAR deve ter ci&ecirc;ncia de que diversos fraudadores tentam se valer de marcas famosas para obter informa&ccedil;&otilde;es pessoais como senhas e dados de cart&otilde;es. O ARMAZ&Eacute;M PARA&Iacute;BA (CLAUDINO S/A - LOJAS DE DEPARTAMENTOS) NUNCA IR&Aacute; SOLICITAR SUA SENHA, NOME DO USU&Aacute;RIO, DADOS DE CART&Atilde;O DE CR&Eacute;DITO OU OUTRAS INFORMA&Ccedil;&Otilde;ES PESSOAIS POR MEIO DE E-MAIL OU CONTATO TELEF&Ocirc;NICO INCLUSIVE SMS.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1.4 Ao aceitar os termos presentes, o TITULAR concorda expressamente e se obriga a fornecer apenas dados pessoais verdadeiros, atuais e precisos de sua pessoa e de DEPENDENTES. Concorda tamb&eacute;m e se obriga a n&atilde;o alterar a identidade ou dados pessoais (PR&Oacute;PRIOS e/ou do(s) DEPENDENTE(S)) de qualquer forma no acesso e na utiliza&ccedil;&atilde;o do CART&Atilde;O PARA&Iacute;BA. O TITULAR &eacute; o &uacute;nico respons&aacute;vel pelas informa&ccedil;&otilde;es falsas, desatualizadas ou imprecisas que fornecer diretamente &agrave; EMITENTE.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">1.5 Ao solicitar, contratar ou Utilizar o CART&Atilde;O DE COMPRAS PARA&Iacute;BA, o TITULAR nos fornece para coleta alguns dados pessoais relacionados &agrave; sua pessoa e eventual dependente sob sua responsabilidade. Alguns dados pessoais poder&atilde;o ser partilhados com terceiros para finalidades espec&iacute;ficas (como para refor&ccedil;ar o controle contra fraudes), para cumprimento de obriga&ccedil;&otilde;es legais ou com a regulamenta&ccedil;&atilde;o aplic&aacute;vel ou com a execu&ccedil;&atilde;o do objeto e do escopo do contrato.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) A EMITENTE poder&aacute; compartilhar os seus dados pessoais do TITULAR e/ou DEPENDENTE(S) com autoridades e &oacute;rg&atilde;os reguladores para diferentes finalidades, quando necess&aacute;rio, com as empresas de prote&ccedil;&atilde;o ao cr&eacute;dito e, ainda, com outras empresas pertencentes ao mesmo grupo econ&ocirc;mico da EMITENTE. Sempre que realizado, o compartilhamento de dados ocorrer&aacute; dentro dos limites e prop&oacute;sitos dos nossos neg&oacute;cios e de acordo com o que autoriza a legisla&ccedil;&atilde;o aplic&aacute;vel;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) Ao aceitar as condi&ccedil;&otilde;es deste contrato, o TITULAR declara estar ciente do inteiro conte&uacute;do da Pol&iacute;tica de Privacidade e Prote&ccedil;&atilde;o de Dados da EMITENTE;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) O TITULAR, como titular de dados pessoais, pode exercer seus direitos frente aos controladores dos seus dados pessoais. As orienta&ccedil;&otilde;es, em rela&ccedil;&atilde;o &agrave; EMITENTE, se encontram detalhadas na nossa Pol&iacute;tica de Privacidade;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">d) Caso o TITULAR verifique que seus dados pessoais ou do(s) DEPENDENTE(S) est&atilde;o incompletos, inexatos ou desatualizados, poder&aacute; pedir a corre&ccedil;&atilde;o ou complementa&ccedil;&atilde;o diretamente a EMITENTE, bastando para tanto entregar diretamente na loja em que fez a solicita&ccedil;&atilde;o do cart&atilde;o, ou enviar um documento que comprove a forma correta e atual do dado pessoal.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">1.6 A EMITENTE fica autorizada pelo TITULAR a compartilhar os dados pessoais fornecidos no presente instrumento, seja do pr&oacute;prio TITULAR ou do(s) DEPENDENTE(S), com outros agentes de tratamento de dados, caso seja necess&aacute;rio para as finalidades legais, observados os princ&iacute;pios e as garantias estabelecidas pela Lei n&ordm; 13.709 (Lei Geral de Prote&ccedil;&atilde;o de Dados).&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">1.7 A EMITENTE poder&aacute; manter e tratar os dados pessoais do TITULAR e do(s) DEPENDENTES durante todo o per&iacute;odo em que os mesmos forem pertinentes ao alcance das finalidades do presente instrumento.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">1.8&nbsp;</span><span lang="pt-BR">Caso seu consentimento seja necess&aacute;rio para acessar ou usar determinado produto ou servi&ccedil;o, voc&ecirc; pode pedir que a EMITENTE esclare&ccedil;a se &eacute; poss&iacute;vel fornecer esse produto ou prestar esse servi&ccedil;o sem o seu consentimento para o tratamento dos seus dados pessoais, ou quais s&atilde;o as consequ&ecirc;ncias de n&atilde;o fornecer o consentimento para este caso, n&atilde;o impedindo o uso de dados anonimizados e dados cujo tratamento esteja baseado em outra hip&oacute;tese legal prevista na Lei Geral de Prote&ccedil;&atilde;o de Dados - LGPD.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">CART&Otilde;ES</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2 Integra o SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA, &uacute;nica e exclusivamente, o CART&Atilde;O DE COMPRAS PARA&Iacute;BA, que poder&aacute; ser, conforme op&ccedil;&atilde;o do TITULAR, concedido pela EMITENTE em duas modalidades: (I) EXTRATO, (II) CARN&Ecirc;.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.1 A idade m&iacute;nima permitida para ingresso no SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA &eacute;: (I) 18 anos para TITULAR; (II) 16 anos para ADICIONAL, preservada a atribui&ccedil;&atilde;o de responsabilidade exclusiva ao TITULAR pelos atos daquele, a partir de ent&atilde;o.&nbsp;</span><span lang="pt-BR">O CART&Atilde;O ADICIONAL &eacute; um cart&atilde;o destinado para os dependentes e SEMPRE ser&aacute; vinculado ao cart&atilde;o do titular e, por isso, compartilha tanto da fatura quanto do limite do cart&atilde;o do TITULAR;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.2 O CART&Atilde;O &eacute; de uso pessoal e intransfer&iacute;vel.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.3 Poder&aacute; o TITULAR optar, no momento do ingresso ao SISTEMA DE CART&Atilde;O DE COMPRAS PARA&Iacute;BA, sobre a forma que deseja eventualmente proceder aos pagamentos dos saldos existentes na sua conta cart&atilde;o, quais sejam (I) EXTRATO, (II) CARN&Ecirc;, que a crit&eacute;rio exclusivo da EMITENTE estejam, &agrave; &eacute;poca, dispon&iacute;veis para utiliza&ccedil;&atilde;o do CART&Atilde;O DE COMPRAS PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.4O (s) CART&Atilde;O(&Otilde;ES) emitido(s) com op&ccedil;&atilde;o de pagamento CARN&Ecirc; n&atilde;o possibilitar&aacute;(&atilde;o) ao TITULAR concess&atilde;o de CART&Atilde;O(&Otilde;ES) ADICIONAL(IS) em face de que, para cada venda efetuada atrav&eacute;s do CART&Atilde;O, existir a obrigatoriedade de individualiza&ccedil;&atilde;o da compra, com a discrimina&ccedil;&atilde;o das parcelas a serem pagas cada uma em seu respectivo vencimento, pelo TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.5 O(s) CART&Atilde;O(&Otilde;ES) emitido(s) com op&ccedil;&atilde;o de pagamento EXTRATO possibilitar&aacute;(&atilde;o) ao TITULAR autorizar, sob sua inteira responsabilidade, conta e risco e, a seu d&eacute;bito, a emiss&atilde;o de CART&Atilde;O(&Otilde;ES) ADICIONAL(IS) para uso de pessoas f&iacute;sicas, parentes ou n&atilde;o que, para esse efeito, ser&atilde;o consideradas como seus dependentes, constituindo-se o Titular no devedor principal das despesas e obriga&ccedil;&otilde;es provenientes da utiliza&ccedil;&atilde;o, devida ou n&atilde;o do(s) CART&Atilde;O(&Otilde;ES).</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.6 O TITULAR constitui-se no devedor principal de todas e quaisquer despesas e obriga&ccedil;&otilde;es provenientes da utiliza&ccedil;&atilde;o,&nbsp;</span><span lang="pt-BR">tanto do cart&atilde;o principal como de eventual cart&atilde;o adicional solicitado,</span><span lang="pt-BR">&nbsp;inclusive indevida&nbsp;</span><span lang="pt-BR">de quaisquer desses</span><span lang="pt-BR">&nbsp;CART&Atilde;O(&Otilde;ES), bem como pela guarda e uso do(s) mesmo(s).</span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR">&Eacute; de responsabilidade do TITULAR prestar unicamente informa&ccedil;&otilde;es corretas, verdadeiras, aut&ecirc;nticas, completas e atualizadas, bem como zelar pelo sigilo de sua senha, ou seja, n&atilde;o divulg&aacute;-la a terceiros.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.7 A EMITENTE atender&aacute; aos pedidos de emiss&atilde;o de CART&Atilde;O(&Otilde;ES) ADICIONAL(IS), desde que preenchidos os requisitos de sua aprova&ccedil;&atilde;o e manuten&ccedil;&atilde;o de cr&eacute;dito vigente, observadas as condi&ccedil;&otilde;es m&iacute;nimas para cada CART&Atilde;O.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.8O s CART&Otilde;ES TITULAR e ADICIONAL(IS) ser&atilde;o entregues ao TITULAR, devidamente BLOQUEADOS, por meios dos servi&ccedil;os dos Correios, por outra empresa qualificada para a presta&ccedil;&atilde;o do respectivo servi&ccedil;o, ou, ainda, por funcion&aacute;rios credenciados pela pr&oacute;pria EMITENTE.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">2.9 O(s) CART&Atilde;O(&Otilde;ES) ADICIONAL(IS) ser&atilde;o remetidos sempre para o endere&ccedil;o do TITULAR, mediante pr&eacute;via e expressa autoriza&ccedil;&atilde;o do mesmo.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.10 A LIBERA&Ccedil;&Atilde;O do(s) CART&Atilde;O(&Otilde;ES) dever&aacute; ser efetuada pelo TITULAR atrav&eacute;s do(s) telefone(s) informados na etiqueta aposta sobre o CART&Atilde;O ou, pessoalmente, em qualquer uma das lojas do&nbsp;</span><span lang="pt-BR">ARMAZ&Eacute;M</span><span lang="pt-BR">&nbsp;PARA&Iacute;BA (CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS,&nbsp;</span><span lang="pt-BR">matriz de CNPJ n.&ordm; 06.862.627/0001-38)</span><span lang="pt-BR">.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.11 O CART&Atilde;O ser&aacute; de pl&aacute;stico e conter&aacute; o nome do TITULAR ou do ADICIONAL e campo espec&iacute;fico para a sua assinatura; ano/m&ecirc;s da concess&atilde;o; prazo de validade; n&uacute;mero de identifica&ccedil;&atilde;o exclusivo composto por doze algarismos; a sigla da loja e o c&oacute;digo de identifica&ccedil;&atilde;o do cliente na Loja que o cadastrou no SISTEMA de CART&Atilde;O DE COMPRAS PARA&Iacute;BA; a logomarca do Armaz&eacute;m PARA&Iacute;BA; a bandeira do Grupo Claudino e, conforme o caso, holograma de seguran&ccedil;a.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">2.12 Havendo necessidade de substitui&ccedil;&atilde;o do CART&Atilde;O, ser&aacute; conservado o mesmo n&uacute;mero do CART&Atilde;O ANTERIOR, em nada alterando os saldos existentes, salvo nos casos de PERDA ou ROUBO, onde, nesses casos espec&iacute;ficos, ser&aacute; atribu&iacute;do um novo n&uacute;mero ao CART&Atilde;O, implicando na transfer&ecirc;ncia autom&aacute;tica dos saldos existentes no CART&Atilde;O ANTERIOR para o novo n&uacute;mero.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">2.13 Ocorrendo solicita&ccedil;&atilde;o de novos cart&otilde;es, seja TITULAR OU ADICIONAL, por perda ou roubo, ou de segunda via destes, em face de extravio ou motivo similar, em datas distintas de seus vencimentos, fica a EMITENTE autorizada a debitar na conta cart&atilde;o do TITULAR, de forma autom&aacute;tica, o valor correspondente ao servi&ccedil;o de regulariza&ccedil;&atilde;o ent&atilde;o solicitado.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">FUN&Ccedil;&Otilde;ES E CRIT&Eacute;RIOS DE UTILIZA&Ccedil;&Atilde;O DO CART&Atilde;O DE COMPRAS PRA&Iacute;BA</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3 Os CART&Otilde;ES DE COMPRAS PARA&Iacute;BA emitidos por CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, sua &uacute;nica propriet&aacute;ria, somente poder&atilde;o ser utilizados, exclusivamente, na FUN&Ccedil;&Atilde;O DE COMPRAS, para aquisi&ccedil;&atilde;o de bens e/ou servi&ccedil;os nas Lojas do&nbsp;</span><span lang="pt-BR">ARMAZ&Eacute;M</span><span lang="pt-BR">&nbsp;PARA&Iacute;BA&nbsp;</span><span lang="pt-BR">pertencentes a empresa</span><span lang="pt-BR">&nbsp;CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS.&nbsp;</span><span lang="pt-BR">N&atilde;o &eacute; permitida a utiliza&ccedil;&atilde;o do cart&atilde;o Para&iacute;ba em estabelecimentos comerciais outros que os n&atilde;o especificados no presente instrumento, ainda que tais estabelecimentos se utilizem do mesmo nome de fantasia e perten&ccedil;am a outras pessoas jur&iacute;dicas.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.1As COMPRAS PARCELADAS, faturadas a d&eacute;bito do TITULAR, tanto na op&ccedil;&atilde;o EXTRATO quanto CARN&Ecirc;, se solicitadas, poder&atilde;o ser financiadas pela EMITENTE, com a incid&ecirc;ncia dos respectivos encargos praticados pela pol&iacute;tica financeira em vigor.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.2 O limite de Compra e o limite adicional para vendas parceladas ser&atilde;o definidos pela EMITENTE e informado ao TITULAR do CART&Atilde;O no deferimento da proposta de Ades&atilde;o ao SISTEMA de CART&Atilde;O DE COMPRAS PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.3 O limite de cr&eacute;dito ser&aacute; concedido apenas ao TITULAR. Havendo ADICIONAL(IS), caber&aacute; ao TITULAR administr&aacute;-lo e por ele responder.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">3.4Quando da solicita&ccedil;&atilde;o de um cart&atilde;o ADICIONAL, o TITULAR, a seu crit&eacute;rio, definir&aacute; o valor mensal para compras que poder&atilde;o ser efetuadas atrav&eacute;s do mesmo, sob sua exclusiva responsabilidade, com a observ&acirc;ncia dos limites de cr&eacute;ditos ent&atilde;o disponibilizados pela EMITENTE.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.5 Se, embora ciente do limite de cr&eacute;dito que lhe foi disponibilizado pela EMITENTE, o TITULAR resolva extrapol&aacute;-lo, estar&aacute;, na primeira vez que o fizer, sujeito &agrave; exig&ecirc;ncia imediata do excesso por ele cometido, e, em caso de reincid&ecirc;ncia, acarretar&aacute;, por conseguinte, o BLOQUEIO de seu cart&atilde;o por tempo indeterminado.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.6 O TITULAR ser&aacute; comunicado atrav&eacute;s de correspond&ecirc;ncia espec&iacute;fica ou por meio de informa&ccedil;&atilde;o contida no pr&oacute;prio EXTRATO da FATURA mensal e /ou CARN&Ecirc; de pagamento, caso haja altera&ccedil;&atilde;o no seu limite de compras, e, em havendo discord&acirc;ncia de sua parte, dever&aacute; express&aacute;-la no prazo de dez (10) dias a contar de sua ci&ecirc;ncia, atrav&eacute;s dos telefones indicados no EXTRATO da FATURA, ou pessoalmente, em qualquer uma das lojas do&nbsp;</span><span lang="pt-BR">ARMAZ&Eacute;M</span><span lang="pt-BR">&nbsp;PARA&Iacute;BA (CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS).&nbsp;</span><span lang="pt-BR">O sil&ecirc;ncio do TITULAR quanto &agrave; altera&ccedil;&atilde;o do limite de cr&eacute;dito no prazo ofertado ser&aacute; entendido como aceito pelo TITULAR</span><span lang="pt-BR">.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">3.7N&atilde;o ser&aacute; cobrada ao TITULAR tarifa de anuidade sobre os cart&otilde;es emitidos para utiliza&ccedil;&atilde;o na fun&ccedil;&atilde;o de compras.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.8 Nas aquisi&ccedil;&otilde;es de bens dur&aacute;veis (eletrodom&eacute;sticos), o TITULAR se compromete, por si e pelo ADICIONAL, at&eacute; a liquida&ccedil;&atilde;o do d&eacute;bito, junto a EMITENTE a: (I) n&atilde;o alienar, gravar ou, por qualquer modo, ceder ou transferir a terceiro(s) o(s) bem(ns); (II) manter os bens em condi&ccedil;&otilde;es de boa conserva&ccedil;&atilde;o e funcionamento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.9 Para cada aquisi&ccedil;&atilde;o de bem(ns) e/ou servi&ccedil;o(s), a empresa emitir&aacute; documento representativo do d&eacute;bito contra&iacute;do, a ser lan&ccedil;ado na conta cart&atilde;o do TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">3.10 Por ser a presente venda feita a cr&eacute;dito, fica pactuada a reserva de dom&iacute;nio da(s) mercadoria(s) constante(s) nos documentos representativos de d&eacute;bitos, Cl&aacute;usula 3.8, obrigando o TITULAR a mant&ecirc;-las sob sua guarda at&eacute; a liquida&ccedil;&atilde;o total do d&eacute;bito existente na conta cart&atilde;o do TITULAR, quando ent&atilde;o, quitado integralmente o d&eacute;bito, adquirir&aacute; o pleno dom&iacute;nio sobre as referidas mercadorias, independente de qualquer formalidade.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">AQUISI&Ccedil;&Atilde;O DE BENS E SERVI&Ccedil;OS</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">4 Para aquisi&ccedil;&atilde;o de bens e servi&ccedil;os, o TITULAR ou ADICIONAL(IS) dever&aacute;(ao) apresentar o CART&Atilde;O &agrave; Loja afiliada em conjunto com um documento de identidade, assinar o comprovante de venda de bens e servi&ccedil;os e receber uma das vias do comprovante de venda. As opera&ccedil;&otilde;es poder&atilde;o ser realizadas em equipamentos eletr&ocirc;nicos ou mec&acirc;nicos, por&eacute;m, sempre com a aposi&ccedil;&atilde;o de assinatura do COMPRADOR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">4.1 Poder&atilde;o, ainda, ser adquiridos bens e servi&ccedil;os por TELEFONE ou INTERNET. Neste caso, o aceite do comprador ser&aacute; efetivado mediante assinatura do comprovante de venda no momento da entrega dos produtos adquiridos ou dos servi&ccedil;os prestados.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">4.2 Cabe tanto ao TITULAR como ao ADICIONAL conferir, previamente, os dados relativos &agrave; opera&ccedil;&atilde;o ent&atilde;o realizada, lan&ccedil;ados no COMPROVANTE DE VENDA emitido pela Loja vendedora, sendo certo que a aposi&ccedil;&atilde;o da respectiva assinatura, de pr&oacute;prio punho, implicar&aacute; na integral responsabilidade do TITULAR pela opera&ccedil;&atilde;o.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">FATURAMENTO E PAGAMENTO</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5 O saldo devedor indicado no EXTRATO do CART&Atilde;O e as presta&ccedil;&otilde;es constantes nos CARN&Ecirc; de pagamentos dever&atilde;o ser quitados pelo TITULAR na data do vencimento estabelecida de comum acordo entre o TITULAR e CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, no ato da ADES&Atilde;O a este contrato.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.1 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS remeter&aacute; mensalmente para o TITULAR do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, o EXTRATO de pagamento indicando: (I) as compras efetuadas no per&iacute;odo; (II) pagamentos efetuados no per&iacute;odo; (III) eventuais encargos do per&iacute;odo; (IV) taxa efetiva mensal e anual dos encargos; (V) outros dados e informa&ccedil;&otilde;es relacionados com a utiliza&ccedil;&atilde;o do CART&Atilde;O. Para os clientes que optarem por pagamentos atrav&eacute;s de CARN&Ecirc;, estas informa&ccedil;&otilde;es dever&atilde;o ser obtidas no momento da compra junto &agrave; loja vendedora.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.2 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, de acordo com as normas vigentes, poder&aacute; admitir pagamento do saldo devedor por percentuais m&iacute;nimos indicados no EXTRATO mensal, n&atilde;o constituindo tal procedimento nova&ccedil;&atilde;o da d&iacute;vida. Nessa hip&oacute;tese, o saldo remanescente poder&aacute; ser financiado pela empresa, nos termos das Cl&aacute;usulas 7 e 7.1 deste instrumento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.3 O n&atilde;o pagamento do EXTRATO at&eacute; a data do vencimento n&atilde;o prejudicar&aacute; a exigibilidade do pagamento do saldo devedor, que poder&aacute; ser obtido, a qualquer momento, atrav&eacute;s dos telefones indicados no EXTRATO da FATURA MENSAL, ou pessoalmente, em qualquer uma das lojas do ARMAZ&Eacute;M PARA&Iacute;BA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.4 As lojas dever&atilde;o sempre ter em seus estabelecimentos, &agrave; disposi&ccedil;&atilde;o do cliente, mecanismos para emiss&atilde;o de segunda via de EXTRATO da FATURA mensal, se comprometido o recebimento deste, possibilitando &agrave; EMITENTE, por tal servi&ccedil;o, inseri-lo, a t&iacute;tulo de d&eacute;bito, direto na conta CART&Atilde;O do TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.5 Os pagamentos realizados pelo TITULAR ser&atilde;o processados: no mesmo dia, se efetuados em qualquer uma das lojas da empresa CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS que utilizem sistema de processamento ON-LINE entre seus estabelecimentos e a CENTRAL DE PROCESSAMENTO situada em Teresina-PI, e, em at&eacute; 10 (dez) dias, quando envolver sistema de compensa&ccedil;&atilde;o atrav&eacute;s de representa&ccedil;&otilde;es n&atilde;o integradas ON-LINE ao Sistema de Inform&aacute;tica. Se nesse interregno, por motivo superveniente e involunt&aacute;rio, for desautorizada, sob reserva, a realiza&ccedil;&atilde;o de novas compras pelo TITULAR, este dever&aacute; procurar a Ger&ecirc;ncia da loja na qual se encontrar, ou ainda, obter orienta&ccedil;&otilde;es junto &agrave; Central de Atendimento pelos telefones indicados no EXTRATO da FATURA mensal, sendo que, em ambas as situa&ccedil;&otilde;es, ser&aacute; prontamente atendido, sempre no intuito de solucionar da melhor forma poss&iacute;vel o impasse ocorrido.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.6 Para efeito da Cl&aacute;usula anterior, considera-se: (I) PAGAMENTO ON-LINE - aquele efetuado em qualquer loja da empresa CLAUDINO S/A&nbsp;</span><span lang="pt-BR">-</span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR">LOJAS DE DEPARTAMENTOS</span><span lang="pt-BR">, interligado ao sistema de inform&aacute;tica; (II) PAGAMENTO POR SISTEMA DE COMPENSA&Ccedil;&Atilde;O - aquele efetuado em qualquer loja ou representa&ccedil;&atilde;o da empresa CLAUDINO S/A&nbsp;</span><span lang="pt-BR">-</span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR">LOJAS DE DEPARTAMENTOS</span><span lang="pt-BR">, n&atilde;o interligada ao Sistema de Inform&aacute;tica da empresa.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.7 Caso o TITULAR requeira outra via de qualquer documento relacionado a opera&ccedil;&atilde;o(&otilde;es) realizada(s) por meio do CART&Atilde;O DE COMPRAS PARA&Iacute;BA, este aceita e reconhece como v&aacute;lido e verdadeiro fac-s&iacute;mile ou fotoc&oacute;pias de documentos de vendas registrados nos computadores da empresa, assim como autoriza a EMITENTE proceder em sua CONTA CART&Atilde;O, de forma autom&aacute;tica, no m&ecirc;s em que ocorrer o servi&ccedil;o, os lan&ccedil;amentos dos valores correspondentes &agrave; emiss&atilde;o dos mesmos.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.8 Se o pagamento do EXTRATO da FATURA mensal ou CARN&Ecirc; for efetuado com cheque, a liquida&ccedil;&atilde;o ocorrer&aacute; ap&oacute;s a compensa&ccedil;&atilde;o do mesmo. Em casos de devolu&ccedil;&atilde;o, implicar&aacute; no retorno do d&eacute;bito para a conta corrente do CART&Atilde;O do TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">5.9 Em se tratando de CART&Atilde;O DE COMPRAS PARA&Iacute;BA, de TITULAR que tenha optado pela forma de pagamento CARN&Ecirc;, todo e qualquer servi&ccedil;o prestado, a requerimento do TITULAR, que gere despesas adicionais &agrave; EMITENTE, ser&aacute; cobrado no momento da execu&ccedil;&atilde;o dos servi&ccedil;os.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">CONTESTA&Ccedil;&Atilde;O DE LAN&Ccedil;AMENTOS</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">6 Sem preju&iacute;zo da exigibilidade do pagamento de cada EXTRATO de FATURA do CART&Atilde;O ou do CARN&Ecirc; no seu respectivo vencimento, o TITULAR poder&aacute; contestar, por escrito, qualquer parcela constante no EXTRATO da FATURA, no prazo de 10 (dez) dias seguintes ao vencimento do respectivo pagamento. O n&atilde;o exerc&iacute;cio dessa faculdade implica reconhecimento pelo TITULAR da exatid&atilde;o do valor constante no EXTRATO.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">6.1 Poder&aacute; se admitir que o EXTRATO da FATURA mensal seja paga, deduzida das parcelas contestadas, sem que tal procedimento constitua nova&ccedil;&atilde;o da d&iacute;vida. Sobre as parcelas contestadas indevidamente e n&atilde;o pagas, incidir&atilde;o os encargos previstos na Cl&aacute;usula 9.1 deste instrumento, calculada da data do vencimento, indicado no EXTRATO da FATURA mensal, at&eacute; a data da efetiva quita&ccedil;&atilde;o.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">FINANCIAMENTO</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">7 Diante da manifesta admiss&atilde;o pelo TITULAR, CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, com a devida observ&acirc;ncia &agrave; pol&iacute;tica financeira praticada, poder&aacute; financiar:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) As compras, com pagamentos parcelados;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) Os saldos devedores remanescentes dos pagamentos do valor m&iacute;nimo;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) Saldos devedores n&atilde;o pagos ou pagos em atraso, inclusive os encargos.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">7.1O s saldos devedores financiados ser&atilde;o computados pela EMITENTE como utiliza&ccedil;&atilde;o do limite de compra, o qual ser&aacute; recomposto proporcionalmente, na medida em que as FATURAS forem sendo quitadas.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">ENCARGOS</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">8Sobre as compras com pagamentos parcelados, incidir&atilde;o encargos indicados no EXTRATO da FATURA mensal. Sobre o saldo devedor n&atilde;o pago ou pago em atraso, bem como o valor remanescente ao pagamento do valor m&iacute;nimo indicado no EXTRATO da FATURA mensal do CART&Atilde;O, incidir&atilde;o encargos indicados no EXTRATO da FATURA posterior.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">INADIMPL&Ecirc;NCIA E RECONHECIMENTO DE D&Iacute;VIDA</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9 &Agrave; falta ou atraso no cumprimento de quaisquer das obriga&ccedil;&otilde;es contratuais, sejam principais ou acess&oacute;rias, autorizar&aacute; a empresa CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS a considerar vencido o contrato em todas as suas obriga&ccedil;&otilde;es e exigir do TITULAR, prontamente, o pagamento de todo o saldo devedor.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.1 Sobre o SALDO DEVEDOR n&atilde;o pago ou pago em atraso incidir&atilde;o:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) Encargos para CR&Eacute;DITO ROTATIVO indicado no EXTRATO da FATURA do CART&Atilde;O.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) Juros de mora de 1% (Um por cento) ao m&ecirc;s ou fra&ccedil;&atilde;o e corre&ccedil;&atilde;o monet&aacute;ria;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) Multa de 2% (dois por cento) sobre o saldo devedor n&atilde;o pago ou pago em atraso, enquanto houver atraso ou falta de pagamento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.2 As compras efetuadas ap&oacute;s o vencimento de eventual FATURA n&atilde;o quitada ter&atilde;o vencimentos imediatos e ser&atilde;o incorporadas ao saldo devedor para efeito de apura&ccedil;&atilde;o dos valores a que se referem &agrave;s al&iacute;neas &ldquo;a&rdquo; e &ldquo;b&rdquo; da Cl&aacute;usula 9.1, independente da emiss&atilde;o de EXTRATO da respectiva FATURA.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.3 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, ap&oacute;s o vencimento da FATURA n&atilde;o quitada, e, diante do n&atilde;o equacionamento do problema pelo TITULAR, depois de comunicado, poder&aacute; incluir o nome do mesmo no cadastro de empresas que gerenciam SERVI&Ccedil;OS DE PROTE&Ccedil;&Atilde;O AO CR&Eacute;DITO.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.4 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS poder&aacute; considerar vencido antecipadamente o presente contrato na ocorr&ecirc;ncia&nbsp;</span><span lang="pt-BR">de quaisquer</span><span lang="pt-BR">&nbsp;as hip&oacute;teses previstas no</span><span lang="pt-BR">s</span><span lang="pt-BR">&nbsp;</span><span lang="pt-BR">art</span><span lang="pt-BR">s</span><span lang="pt-BR">. 333 e 1.425 do C&oacute;digo Civil&nbsp;</span><span lang="pt-BR">(Lei n.&ordm; 10.406, de 10 de janeiro de 2002)</span><span lang="pt-BR">, bem como: (I) se o TITULAR deixar de cumprir qualquer obriga&ccedil;&atilde;o contida neste contrato; (II) se o TITULAR sofrer protesto de t&iacute;tulo por alguma das empresas coligadas de CLAUDINO S/A&nbsp;</span><span lang="pt-BR">&ndash; LOJAS DE DEPARTAMENTOS</span><span lang="pt-BR">; (III) por emiss&atilde;o de cheque sem fundo pelo TITULAR.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">9.5 Quando da necess&aacute;ria utiliza&ccedil;&atilde;o por CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS dos servi&ccedil;os de advogados, seja na fase extrajudicial ou judicial, para promover cobran&ccedil;a do saldo devedor da conta corrente e planos especiais (CARN&Ecirc;), o TITULAR compromete-se a pagar as despesas da&iacute; advindas, inclusive corre&ccedil;&atilde;o monet&aacute;ria, juros morat&oacute;rios de 1% (um por cento) ao m&ecirc;s e honor&aacute;rios advocat&iacute;cios de 20% (vinte por cento) sobre o valor apurado.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.6 O TITULAR reconhece a FATURA mensal como prova de seu d&eacute;bito, e que os valores lan&ccedil;ados no EXTRATO da FATURA mensal constituem sua d&iacute;vida l&iacute;quida, certa e exig&iacute;vel para com a Emitente, salvo manifesta diverg&ecirc;ncia, apontada pelo TITULAR no prazo de at&eacute; 10 dias contados da data do vencimento da FATURA, indicado no EXTRATO de pagamento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.7 O TITULAR tamb&eacute;m reconhece que este contrato, acompanhado do EXTRATO da FATURA vencida e n&atilde;o paga, constitui t&iacute;tulo executivo extrajudicial, nos termos previstos no artigo 585, II, do C&oacute;digo de Processo Civil.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.8 O TITULAR e adicional(is) poder&aacute;( o) ser(em) exclu&iacute;do(s) do Sistema de Cart&atilde;o de Compras Para&iacute;ba, se verificado:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) O n&atilde;o pagamento ou pagamento parcial do debito, no caso de extrato, depois de decorrido o prazo de 05(cinco) dias do vencimento do terceiro extrato consecutivo;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) O n&atilde;o pagamento ou pagamento parcial do debito, no caso de carn&ecirc;, depois de decorrido o prazo de 95(noventa e cinco) dias do vencimento da primeira presta&ccedil;&atilde;o em aberto;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">Par&aacute;grafo Primeiro: A exclus&atilde;o poder&aacute; ocorrer mesmo que venha o referido debito ser adimplido ap&oacute;s o decurso dos prazos previstos nas al&iacute;neas &ldquo;a&rdquo; e &ldquo;b&rdquo; deste item.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">Par&aacute;grafo Segundo: Os valores devidos nas hip&oacute;teses previstas neste item, ser&atilde;o acrescidos dos encargos financeiros at&eacute; a data de sua exclus&atilde;o e passar&atilde;o a ser atualizados pela corre&ccedil;&atilde;o monet&aacute;ria com base na varia&ccedil;&atilde;o acumulada do IGPM/FGV(&Iacute;ndice Geral de Pre&ccedil;os de Mercadorias) a ser aplicada sobre a quantia em d&eacute;bito, desde a da data de seus vencimentos at&eacute; a data de seus efetivos pagamentos aplicadas &ldquo;pro-rata-dies&rdquo;, sem preju&iacute;zo dos demais encargos previstos neste instrumento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.9 Nos casos de que tratam as Cl&aacute;usulas 9 a 9.8, estar&aacute; CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS automaticamente autorizada, usando os poderes constantes da procura&ccedil;&atilde;o que, por este instrumento, lhe confere o TITULAR a contratar, em Institui&ccedil;&atilde;o Financeira de sua livre escolha, o financiamento do saldo devedor atual do TITULAR, em cada m&ecirc;s, o qual ser&aacute; acrescida de juros e das demais despesas financeiras incidentes, calculada dia-a-dia, conforme contrato com a Institui&ccedil;&atilde;o Financeira;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.10 Para os efeitos do disposto na cl&aacute;usula anterior, o TITULAR, por esta e na melhor forma do direito, nomeia e constitui CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS sua bastante procuradora, outorgando-lhe todos os poderes necess&aacute;rios, amplos e irrevog&aacute;veis para, em seu nome (TITULAR), negociar e obter cr&eacute;dito junto &agrave; Institui&ccedil;&atilde;o Financeira de livre escolha da mandat&aacute;ria, para o financiamento das compras e despesas de responsabilidade do TITULAR, ficando a mandat&aacute;ria investida de poderes especiais para assinar os contratos necess&aacute;rios, emitir t&iacute;tulos de cr&eacute;ditos representativos do d&eacute;bito do TITULAR, acertar prazos, cl&aacute;usulas penais, juros, comiss&otilde;es e demais despesas de d&iacute;vidas, ressalvando-se que tais acr&eacute;scimos ser&atilde;o absorvidos pelo TITULAR como l&iacute;quidos e certos, cobr&aacute;veis pela via executiva.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">9.11 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS poder&aacute; a seu crit&eacute;rio fazer a reintegra&ccedil;&atilde;o no Sistema de Cart&atilde;o Para&iacute;ba do Titular e adicional(is) exclu&iacute;dos, mediante reavalia&ccedil;&atilde;o cadastral deste(s), o que poder&aacute; implicar na exig&ecirc;ncia de valores a t&iacute;tulo de entrada para financiamentos de vendas futuras.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">BLOQUEIO E CANCELAMENTO DOS CART&Otilde;ES</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">10 O(s) CART&Atilde;O(&Otilde;ES) do TITULAR e o(s) ADICIONAL(IS) ser&aacute;(&atilde;o) bloqueado(s) caso n&atilde;o sejam quitadas as FATURAS no vencimento, ou o valor amortizado for inferior ao valor do pagamento m&iacute;nimo indicado no EXTRATO da FATURA. Somente ap&oacute;s a quita&ccedil;&atilde;o da(s) FATURA(S) ser&aacute; permitida a utiliza&ccedil;&atilde;o do(s) CART&Atilde;O(&Otilde;ES).</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">10.1 Nos casos de pagamentos atrav&eacute;s de CARN&Ecirc;, o bloqueio do CART&Atilde;O do TITULAR ser&aacute; efetivado ap&oacute;s vencida a primeira presta&ccedil;&atilde;o e n&atilde;o pago. Somente ap&oacute;s a quita&ccedil;&atilde;o da(s) presta&ccedil;&atilde;o(&otilde;es) ser&aacute; permitida a utiliza&ccedil;&atilde;o do(s) CART&Atilde;O(&Otilde;ES).&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">10.2 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, poder&aacute; proceder ao bloqueio e/ou cancelamento do(s) CART&Atilde;O(&Otilde;ES), TITULAR e ADICIONAL(IS), se configurado que o TITULAR n&atilde;o atende os pr&eacute;-requisitos exigidos pela contrata&ccedil;&atilde;o de opera&ccedil;&otilde;es de cr&eacute;dito, conforme determinado pela Resolu&ccedil;&atilde;o n&ordm;. 3.310 do Conselho Monet&aacute;rio Nacional.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">RESPONSABILIDADES</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">11 O TITULAR ficar&aacute; como respons&aacute;vel pela utiliza&ccedil;&atilde;o indevida do(s) CART&Atilde;O(&Otilde;ES) a ele vinculado(s), que terceiros hajam feitos ou venham a fazer at&eacute; a data e hora da recep&ccedil;&atilde;o da comunica&ccedil;&atilde;o de roubo, furto, perda e/ou extravio, feita a CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, por meio da Central de Atendimento (telefones indicados nas FATURAS), ou pessoalmente, em qualquer uma das lojas do ARMAZ&Eacute;M PARA&Iacute;BA, dentro do hor&aacute;rio comercial.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">11.1 Nas comunica&ccedil;&otilde;es de furto, roubo, perda e/ou extravio referidas na cl&aacute;usula anterior, o comunicante receber&aacute; de CLAUDINO S/A um c&oacute;digo de atendimento num&eacute;rico, o qual constituir&aacute; confirma&ccedil;&atilde;o e identifica&ccedil;&atilde;o do pedido de bloqueio.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">11.2 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, caso se fa&ccedil;a necess&aacute;rio, fica autorizada a ceder, transferir, dar em penhor ou caucionar o cr&eacute;dito oriundo deste instrumento, inclusive de financiamento concedido ao TITULAR, bem como ceder os direitos de t&iacute;tulos, garantias ou interesses seus a terceiros, na forma prevista na legisla&ccedil;&atilde;o aplic&aacute;vel &agrave; mat&eacute;ria.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">CADASTRO</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">12 O TITULAR dever&aacute; informar de imediato a CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS a mudan&ccedil;a de seu endere&ccedil;o, sob pena de responder, o mesmo, por todos os preju&iacute;zos ocasionados pela falta deste procedimento.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">12.1 Ao aderir ao SISTEMA de CART&Atilde;O DE COMPRAS PARA&Iacute;BA, o nome, a identifica&ccedil;&atilde;o e outros dados pessoais e de consumo do TITULAR ou do(s) ADICIONAL(IS) passar&atilde;o a integrar o cadastro de dados de propriedade de CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, que, desde j&aacute;, fica autorizada a deles se utilizar, respeitadas as disposi&ccedil;&otilde;es legais em vigor.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">12.2 O TITULAR e o(s) ADICIONAL(IS) autorizam a CLAUDINO S/A a verificar informa&ccedil;&otilde;es cadastrais, credit&iacute;cias e financeiras objetivas a seu respeito, necess&aacute;rias e relacionadas com a contrata&ccedil;&atilde;o desta opera&ccedil;&atilde;o.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">12.3 A vig&ecirc;ncia do presente contrato ser&aacute; de 2 (dois) anos. Salvo manifesta&ccedil;&atilde;o em contr&aacute;rio, as renova&ccedil;&otilde;es ser&atilde;o autom&aacute;ticas, por per&iacute;odos iguais e sucessivos, sem &ocirc;nus de pagamento de anuidades.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">RESILI&Ccedil;&Atilde;O</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">13 A qualquer tempo poder&atilde;o as partes resilir o presente contrato, comunicando por escrito a sua decis&atilde;o. Nessa hip&oacute;tese, o TITULAR dever&aacute; devolver o(s) CART&Atilde;O(&Otilde;ES) sob a sua responsabilidade &ndash; inclusive os ADICIONAL(IS) &ndash; devidamente inutilizados e permanecer&aacute; respons&aacute;vel pelos d&eacute;bitos remanescentes decorrentes deste contrato. Esses d&eacute;bitos dever&atilde;o ser pagos pelo TITULAR de uma s&oacute; vez e de forma imediata.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">13.1 Quando a resili&ccedil;&atilde;o for proposta telo TITULAR, este dever&aacute; proceder &agrave; liquida&ccedil;&atilde;o do saldo devedor eventualmente verificado por CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS, imediatamente &agrave; propositura do pedido.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;">13.2 Quando da resili&ccedil;&atilde;o contratual, e, desde que comprovada a inexist&ecirc;ncia de ilicitude na conduta do TITULAR, ser-lhe-&aacute; feita a devolu&ccedil;&atilde;o dos valores que eventualmente se mostrem cab&iacute;veis.&nbsp;</p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">RESCIS&Atilde;O</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">14 Constituir&aacute; causa de rescis&atilde;o do contrato e conseq&uuml;ente cancelamento dos cart&otilde;es:</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">a) O descumprimento de cl&aacute;usulas contratuais;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">b) A verifica&ccedil;&atilde;o por CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS de informa&ccedil;&otilde;es inver&iacute;dicas e/ou insuficientes prestadas pelo TITULAR;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">c) A m&aacute;-f&eacute; do TITULAR e do(s) ADICIONAL(IS) na utiliza&ccedil;&atilde;o dos cart&otilde;es.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">d) As situa&ccedil;&otilde;es que alterem negativamente o perfil de cr&eacute;dito verificado na proposta de ADES&Atilde;O do TITULAR;</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">14.1 Rescindindo este Contrato, ficar&aacute; automaticamente cancelada a validade de todos o(s) CART&Atilde;O(&Otilde;ES), inclusive o(s) ADICIONAL(IS), obrigando-o ao TITULAR a restitu&iacute;-los &agrave; Emitente imediatamente, independente de solicita&ccedil;&atilde;o.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">14.2 A negativa da restitui&ccedil;&atilde;o poder&aacute; enquadrar o seu detentor nas penas do artigo 168 do C&oacute;digo Penal, e o uso do(s) CART&Atilde;O(&Otilde;ES) ap&oacute;s o seu cancelamento enquadrar&aacute; o seu autor nas penas previstas no artigo 171 do C&oacute;digo Penal.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">MODIFICA&Ccedil;&Otilde;ES</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">15 CLAUDINO S/A &ndash; LOJAS DE DEPARTAMENTOS poder&aacute; ampliar a qualquer tempo a utilidade do(s) CART&Atilde;O(&Otilde;ES), agregando-lhes outros servi&ccedil;os, com as devidas adequa&ccedil;&otilde;es deste Contrato, por meio de termo aditivo operacional, mediante comunica&ccedil;&atilde;o escrita ou mensagem lan&ccedil;adas nos EXTRATOS de FATURAS mensais e/ou CARN&Ecirc;S de pagamento, e registro em Cart&oacute;rio.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">15.1 Fica assegurado ao TITULAR o direito de manifestar-se contrariamente aos novos servi&ccedil;os, at&eacute; 30 dias a partir do recebimento da referida comunica&ccedil;&atilde;o ou mensagem. A perman&ecirc;ncia no Sistema, ap&oacute;s esse per&iacute;odo, confirma a ades&atilde;o do TITULAR aos novos servi&ccedil;os.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">15.2 Declaro, como TITULAR, que li e estou plenamente ciente das condi&ccedil;&otilde;es de tratamento dos meus dados pessoais (e do(s) DEPENDENTE(S)) da Pol&iacute;tica de Privacidade de CLAUDINO S/A - LOJAS DE DEPARTAMENTOS e forne&ccedil;o meu consentimento livremente, quando aplic&aacute;vel, conforme descrito na respectiva Pol&iacute;tica de Privacidade.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">SUCESS&Atilde;O</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">16 Este Contrato obriga as partes, seus herdeiros e sucessores, aplicando-se, no que couber, a legisla&ccedil;&atilde;o de Defesa do Consumidor,&nbsp;</span><span lang="pt-BR">a LGPD</span><span lang="pt-BR">.</span></p>
                <p style="color: rgb(0, 0, 0);background: transparent;text-indent: 0.1cm;margin-bottom: 0cm;border-top: none;border-bottom: 1px solid rgb(255, 0, 0);border-left: none;border-right: none;padding: 0cm;line-height: 120%;break-after: avoid;text-align: justify;"><strong><span lang="pt-BR">FORO</span></strong></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><br></p>
                <p style="color: rgb(0, 0, 0);background: transparent;margin-left: 0.7cm;text-indent: -0.7cm;margin-bottom: 0cm;line-height: 120%;text-align: justify;"><span lang="pt-BR">17 As partes elegem preferencialmente o foro da cidade de Teresina, capital do Estado do Piau&iacute;, como competente para dirimir quaisquer quest&otilde;es resultantes do presente instrumento, observando-se os demais casos previstos em Lei.</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import printJS from 'print-js'
export default {
    data() {
        return {
            mobile: this.isMobile(),
            routeName: this.$route.name,
        }
    },
    methods: {
        printContract(){
            printJS('docs/contract_ncp.pdf');
        },
        //is mobile?
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
    },
}
</script>
<style>
    .btn-ptint-contract{
        width: auto!important;
        margin: 20px!important;
        font-weight: bold!important;
        position: absolute!important;
        top: 3px!important;
        text-decoration: none;
        border-radius: 30px!important;
    }

    @media only screen and (max-width: 800px) {
        .font-contract.contract-resp{
            margin-top: 20px;
        }
        .font-contract .title-contract{
            font-size: 1em!important;
            margin-bottom: 1rem!important;
        }
    }
</style>