<template>
    <div>

        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="inicio">
            <!-- Contrato mobile -->
            <el-button id="contract-mobile" tipo_doc="2" type="danger" size="mini" round @click="viewDocsHome = true">Contrato</el-button>
            <!-- <a
                class="nav-link smoothScroll"
                id="contract-mobile"
                href="javascript:void(0)"
                @click="viewDocsHome = true"
            >Contrato</a> -->

            <div class="container">
                <a class="navbar-brand" href="">
                <img
                    src="@/assets/images/logo_paraiba.png"
                    alt=""
                    class="img-logo img-fluid"
                />
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <!-- <li class="nav-item active">
                        <a
                            class="nav-link"
                            href="javascript:void(0)"
                            @click="goToView('inicio')"
                            >Início</a
                        >
                        </li> -->
                        <li class="nav-item">
                        <a
                            class="nav-link smoothScroll"
                            href="javascript:void(0)"
                            @click="goToView('vantagens')"
                            >Vantagens</a
                        >
                        </li>
                        <li class="nav-item">
                        <a
                            class="nav-link smoothScroll"
                            href="javascript:void(0)"
                            @click="goToView('documentos')"
                            >Documentos Necessários</a
                        >
                        </li>
                        <li class="nav-item">
                        <a
                            class="nav-link smoothScroll"
                            href="javascript:void(0)"
                            @click="goToView('pedir')"
                            >Pedir Meu Cartão</a
                        >
                        </li>
                        <li class="nav-item">
                        <a
                            class="nav-link smoothScroll"
                            id="contract"
                            href="javascript:void(0)"
                            tipo_doc="2"
                            @click="openContract"
                            >Contrato</a
                        >
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Header -->
        <header class="banner">
            <div class="container h-100">
                <div class="row h-100 align-items-center">
                <div class="col-lg-5">
                    <p class="mt-4 px-5 img-text-banner">
                    <img src="@/assets/images/texto.png" alt="" class="img-fluid" />
                    </p>
                    <p class="lead mb-5 text-white-50 px-5">
                    <a
                        type="button"
                        class="btn btn-warning btn-pecaja"
                        href="javascript:void(0)"
                        @click="goToView('pedir')"
                        >Peça agora</a
                    >
                    </p>
                </div>
                </div>
            </div>
        </header>

        <!-- Page Content -->
        <div class="mb-5">
            <img src="@/assets/images/detalhe_1.jpg" alt="" class="w-100 img-fluid" />
        </div>
        <section class="mt-4 py-5" id="vantagens">
            <div class="container">
                <div class="row">
                <div class="call-home col-md-8 m-auto text-center mb-5">
                    <h2>A facilidade começa na solicitação do cartão</h2>
                    <p>
                    Para ter o seu novo Cartão Paraíba basta preencher a proposta de
                    adesão. E em poucos dias você vai ter mais facilidades na sua
                    vida.
                    </p>
                </div>
                </div>

                <div class="row">
                <div class="col-md-3 mb-2 card-easy">
                    <div class="card h-100 shadow-sm rounded">
                    <img class="" src="@/assets/images/icon_3.png" alt="" />
                    <div class="card-body">
                        <h4 class="card-title">É totalmente Grátis</h4>
                        <p class="card-text text-justify">
                        Para obter seu Cartão Paraíba além de ser bem rápido e fácil,
                        você
                        <strong>não paga taxa de adesão e nem anuidades.</strong>
                        </p>
                    </div>
                    </div>
                </div>
                
                <!--
                <div class="col-md-3 mb-2 card-easy">
                    <div class="card h-100 shadow-sm rounded">
                    <img class="" src="@/assets/images/icon_4.png" alt="" />
                    <div class="card-body">
                        <h4 class="card-title">Ganha bônus</h4>
                        <p class="card-text text-justify">
                        Acumule bônus para serem usados como descontos em compras.
                        <br />E nas compras de produtos das Marcas Exclusivas Paraíba,
                        o valor do bônus dobra.
                        </p>
                    </div>
                    </div>
                </div>
                -->
                <div class="col-md-3 mb-2 card-easy">
                    <div class="card h-100 shadow-sm rounded">
                    <img class="" src="@/assets/images/icon_4.png" alt="" />
                    <div class="card-body">
                        <h4 class="card-title">Cashback</h4>
                        <p class="card-text text-justify">
                            Ganhe cashback para ser usado nas próximas compras.
                        <br/>
                        <br />O valor do seu cashback dobra na
                              compra de produtos das marcas
                              exclusivas moda Paraíba (Win,
                              Vizzual, Emepê, Opcion, Kids & Kids
                              e Pink Yellow).
                        </p>
                    </div>
                    </div>
                </div>
                <div class="col-md-3 mb-2 card-easy">
                    <div class="card h-100 shadow-sm rounded">
                    <img class="" src="@/assets/images/icon_2.png" alt="" />
                    <div class="card-body">
                        <h4 class="card-title">Descontos exclusivos</h4>
                        <p class="card-text text-justify">
                        - <strong>20%</strong> de desconto na compra dos produtos das
                        Marcas Exclusivas Paraíba, no mês de seu aniversário.<small
                            class="text-danger"
                            >*</small
                        ><br />
                        </p>
                        <p>
                        - <strong>10%</strong> de desconto no setor de modas no ato do
                        desbloqueio inicial do Cartão Paraíba.<small
                            class="text-danger"
                            >**</small
                        >
                        </p>
                    </div>
                    </div>
                </div>
                <!-- <div class="col-md-3 mb-2 card-easy ">
                                <div class="card h-100 shadow-sm rounded">
                                    <img class="" src="@/assets/images/icon_1.png" alt="">
                                    <div class="card-body">
                                        <h4 class="card-title">Rapidez no atendimento</h4>
                                        <p class="card-text text-justify">Com o novo Cartão Paraíba você não precisa fazer cadastro de compra. Basta apresentar o seu cartão e escolher as opções de parcelamento.</p>
                                    </div>
                                </div>
                            </div> -->
                <div class="col-md-3 mb-2 card-easy">
                    <div class="card h-100 shadow-sm rounded">
                    <img class="" src="@/assets/images/icon_1.png" alt="" />
                    <div class="card-body">
                        <h4 class="card-title">Mais facilidades para pagar</h4>
                        <p class="card-text text-justify">
                        Você pode ter até 59 dias para pagar a primeira parcela em
                        compras do setor de modas.
                        </p>
                    </div>
                    </div>
                </div>

                <small class="ml-2">
                    <strong class="ml-2 text-danger">*</strong> Válido para uma compra.<br />
                    <strong class="ml-2 text-danger">**</strong> Nas lojas de Teresina e Timon
                    10% de desconto no setor de moda, no ato da entrega do Cartão Paraíba da
                    loja.
                </small>
                <br>
                </div>
                <!-- Modal Marcas Exclusivas -->
                <template>
                <el-button
                    class="mt-3"
                    type="text"
                    style="margin-top: -20px; color: #212529"
                    @click="outerVisible = true"
                >
                    Confira aqui nosso rol de Marcas Exclusivas.
                    <img width="20" src="@/assets/images/wink.png" alt="" />
                </el-button>

                <el-dialog
                    :width="width"
                    title="Marcas Exclusivas Paraíba"
                    :visible.sync="outerVisible"
                >
                    <el-row :class="[outerVisible ? 'marc-resp' : '']">
                    <el-col
                        class="colxl-2 m-2"
                        style="width: 100px"
                        v-for="(img, index) in exclusiveMarc"
                        :key="index"
                    >
                        <el-image :src="require(`../assets/${img}`)"> </el-image>
                    </el-col>
                    </el-row>
                    <div slot="footer" class="dialog-footer">
                    <el-button @click="outerVisible = false">OK</el-button>
                    </div>
                </el-dialog>
                </template>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <div class="mt-5">
            <img src="@/assets/images/detalhe_2.jpg" alt="" class="w-100 img-fluid" />
        </div>
        <section class="cart-publ" id="documentos">
            <div class="container">
                <div class="row">
                <div class="col-md-10 m-auto pt-5">
                    <img
                    src="@/assets/images/cartao_gratis.png"
                    alt=""
                    class="img-fluid"
                    />
                </div>
                </div>
            </div>
        </section>
        <section class="requisitos">
            <div class="container">
                <div class="row">
                <div class="publicidade-home col-md-8 m-auto text-center py-5">
                    <p>
                    Para sua proposta ser analisada é necessário nos enviar os
                    seguintes documentos:
                    </p>
                    <ul class="round">
                    <li class="py-3">CPF</li>
                    <li class="py-3">Identidade ou CNH</li>
                    <li class="py-3">
                        Comprovante de Residência Atualizado
                        <ul class="sub-round">
                        <li>
                            Conta de luz, água ou telefone com o mesmo endereço da
                            proposta
                        </li>
                        </ul>
                    </li>
                    <li class="py-3">
                        Comprovante de Renda Atualizado
                        <ul class="sub-round">
                        <li>Mês atual ou anterior</li>
                        <li>Contracheque;</li>
                        <li>Extrato bancário (recebido em casa);</li>
                        <li>Fatura de um cartão de crédito;</li>
                        <li>Extrato do FGTS;</li>
                        <li>Notificação do imposto de renda ou Carnê do INSS</li>
                        <li>Quitações de compras em seu nome</li>
                        </ul>
                    </li>
                    </ul>
                    <div class="lead mb-5 text-white-50 pt-5">
                    <a
                        href="javascript:void(0)"
                        @click="goToView('pedir')"
                        class="btn btn-warning btn-pecaja text-white"
                    >
                        Peça agora o seu!
                    </a>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section>
            <div class="">
                <img
                src="@/assets/images/bg_pecaja.jpg"
                alt=""
                class="w-100 img-fluid"
                />
            </div>
            <div id="pedir" class="container pecajaoseu">
                <div>
                <div class="col-md-9 mx-auto shadow p-3 mb-5 bg-white">
                    <div class="col-md-2 mx-auto seta">
                    <img
                        src="@/assets/images/seta_botao.png"
                        alt=""
                        class="img-fluid"
                    />
                    </div>
                    <div class="col-md-12">
                    <template v-if="resend == true">
                        <Resend :data="dataResend" />
                    </template>
                    <template v-if="resend != true">
                        <Form 
                            :viewDocsHome = viewDocsHome 
                            @viewDocsHome="viewDocsHome = $event"
                            @action="manageAction($event)"
                        />
                    </template>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <footer id="footer" class="py-5 bg-dark">
            <el-button
                id="btnTop"
                class="btn-to-top d-none"
                @click="goToView('inicio')"
                icon="el-icon-top"
                round
                >Topo</el-button
            >

            <div class="container">
                <p class="m-0 text-center text-white">
                Armazém Paraíba &copy; 2021 - Todos os direitos reservados
                </p>
            </div>
        </footer>
    </div>
</template>
<script>
import Form from "@/components/Form.vue";
import Resend from "@/components/Resend.vue";

var $ = require("jquery");

export default {
    components: { Form, Resend },
    data() {
        return {
            width: null,
            resend: false,
            dataResend: [],
            outerVisible: false,
            innerVisible: false,
            exclusiveMarc: [
                "images/win.png",
                "images/vizzual.png",
                "images/option.png",
                "images/emepe.png",
                "images/kids.png",
                "images/pink.png",
            ],
            cookeisAccept: false,
            viewDocsHome: false
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        openContract(){
            window.open('docs/contract_ncp.pdf').focus();
        },
        handleResize() {
            this.width = window.innerWidth < 1024 ? "100%" : "50%";
        },
        goToView(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth",
            });
        },
        manageAction(event) {
            if (event.status === "REENVIO") {
                this.resend = true;
                this.dataResend = event;
            }
        },
    },
    mounted() {
        let self = this;
        let urlParams = new URLSearchParams(window.location.search);
        let type = urlParams.get("type");

        if (type) {
            setTimeout(function () {
                self.goToView("pedir");
            }, 200);
        }

        window.document.body.onscroll = function () {
            if (document.documentElement.scrollTop > 500) {
                $("#btnTop").removeClass("d-none");
            } else {
                $("#btnTop").addClass("d-none");
            }
        };
    },
};
</script>
<style>
    #contract-mobile{
        margin: 5px;
        display: none!important;
        background: transparent;
        color: white;
        border-color: white;
        font-weight: 600;
        position: absolute!important;
        right: 0!important;
        top: 0!important;
    }
    @media only screen and (max-width: 800px) {
        #contract-mobile{
            display: block!important;
        }
        .btn-pecaja{
            font-size: 1em!important;
        }
    }
</style>
